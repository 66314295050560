import React, { useState } from "react";

const domainpath = process.env.REACT_APP_DOMAIN;

const IframeSection = ({ authToken }) => {
  const [isOpen, setIsOpen] = useState(false);

  const iframeCode = `<iframe 
  src="${domainpath}/?authtoken=${authToken}" 
  title="Employer Jobs" 
  width="100%"
  height="1000px">
</iframe>`;

  return (
    <div className="bg-gray-100 p-6 rounded-lg mt-6 mb-6 shadow-md">
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-2xl font-bold">Embed Job Listings</h2>
        <button
          className={`transform transition-transform duration-200 ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          ▼
        </button>
      </div>

      {isOpen && (
        <div className="mt-4">
          <textarea
            readOnly
            value={iframeCode}
            className="w-full p-4 border border-gray-300 rounded-md custom-scrollbar"
            rows="6"
          />
          <button
            onClick={() => navigator.clipboard.writeText(iframeCode)}
            className="bg-blue-500 text-white px-4 py-2 rounded-md mt-4"
          >
            Copy to Clipboard
          </button>
        </div>
      )}
    </div>
  );
};

export default IframeSection;
