import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import api from "../../Api";

const VisitorHistoryPage = () => {
  const { ip } = useParams(); // Retrieve the IP address from the URL
  const [visitorHistory, setVisitorHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchVisitorHistory() {
      try {
        const response = await api.getVisitorHistory(ip); // Call your API
        if (response.success) {
          setVisitorHistory(response.history);
        } else {
          console.error("Failed to fetch visitor history");
        }
      } catch (error) {
        console.error("Error fetching visitor history:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchVisitorHistory();
  }, [ip]);

  const columns = [
    {
      name: "Date",
      selector: (row) => new Date(row.date).toLocaleString(),
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: "Time Spent (sec)",
      selector: (row) => row.timeSpent,
      sortable: true,
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
      sortable: true,
    },
    {
      name: "Page URL",
      selector: (row) => row.pageUrl,
      sortable: true,
    },
  ];

  const tableData = {
    columns,
    data: visitorHistory,
  };

  return (
    <div className="py-8 bg-white p-4 rounded-lg shadow">
      <h2 className="text-lg font-bold mb-4" style={{ color: "#003366" }}>
        Visitor History for IP: {ip}
      </h2>
      {loading ? (
        <p>Loading...</p>
      ) : visitorHistory.length > 0 ? (
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={visitorHistory}
            defaultSortField="date"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      ) : (
        <p>No history found for this IP.</p>
      )}
    </div>
  );
};

export default VisitorHistoryPage;
