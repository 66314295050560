// AdminLayout.js
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import "../../../src/css/responcive.css";
import IframeCode from "../../components/Website/IframeCode.js";
import { CVDownloadProvider } from "../../helpers/CVDownloadContext";
import { Header, EmployerHeader, CandidateHeader } from "./Header";
import { PopupProvider } from "../Common/PopupContext";
import { getUserData } from "../../helpers/utils";
import NotFound from "../Website/NotFound.js";

function Websitelayout() {
  const useLocalStorage = (key) => {
    const storedValue = localStorage.getItem(key);
    return storedValue;
  };

  const user = useLocalStorage("accessToken");
  useEffect(() => {}, [user]);
  const User = getUserData(); // Get user data from localStorage
  const role = User ? User.role : null; // Get the base path for nested routes
  const renderComponentBasedOnRole = (role) => {
    switch (role) {
      case "superadmin":
        return <EmployerHeader />;
      case "applicant":
        return <CandidateHeader />;
      case "employer":
        return <EmployerHeader />;
      default:
        return <Header />; // Default to general header for non-logged-in users or unknown roles
    }
  };

  return (
    <CVDownloadProvider>
      <PopupProvider>
        <Switch>
          <Route exact path="/" component={IframeCode} />
          <Route path="*" component={NotFound} />
        </Switch>
      </PopupProvider>
    </CVDownloadProvider>
  );
}

export default Websitelayout;
