import React, { useEffect, useState, useRef } from "react";
import CustomToastContainer from "../Common/CustomToastContainer";
import api from "../../Api";
import { usePopup } from "../Common/PopupContext";
import { routesConfig } from "../../helpers/Object";
import SiteMapPopup from "../popup/SiteMapPopup";
import { logDOM } from "@testing-library/react";
const domainpath = "https://hirepros.co.uk";

const Sitemap = () => {
  const toastRef = useRef();
  const [jobs, setJobs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const [selectAllPages, setSelectAllPages] = useState(false);
  const [selectAllJobs, setSelectAllJobs] = useState(false);
  const [selectAllBlogs, setSelectAllBlogs] = useState(false);
  const [page, setPage] = useState(1); // Pagination page state
  const limit = 10;
  useEffect(() => {
    async function fetchJobs() {
      try {
        const response = await api.getAlljobsForAdmin(page, limit);
        if (response.status !== 400) {
          const jobsWithUrls = response?.jobsWithApplicants.map((job) => ({
            id: job._id,
            title: job.title,
            url: `${domainpath}/jobs/${job?._id}?title=${job?.title.replace(
              /\s+/g,
              "-"
            )}&company=${job?.company?.name.replace(/\s+/g, "-")}`,
          }));
          setJobs((prevJobs) => [...prevJobs, ...jobsWithUrls]); // Append new jobs
        }
      } catch (error) {
        console.error(error);
      }
    }

    fetchJobs();
  }, [page]);
  useEffect(() => {
    async function getBlogs() {
      try {
        const response = await api.getBlogs();
        if (response.status === 200) {
          const blogsWithUrls = response?.blogs.map((blog) => ({
            id: blog._id,
            title: blog.title,
            url: `${domainpath}/blogs/${blog?._id}?title=${blog?.title.replace(
              /\s+/g,
              "-"
            )}`,
          }));
          setBlogs(blogsWithUrls);
        }
      } catch (error) {
        console.error("Error fetching blogs", error);
      }
    }
    getBlogs();
  }, []);
  const handlePageCheckboxChange = (url) => {
    if (selectedPages.includes(url)) {
      setSelectedPages(selectedPages.filter((item) => item !== url));
    } else {
      setSelectedPages([...selectedPages, url]);
    }
  };
  const handleJobCheckboxChange = (url) => {
    if (selectedJobs.includes(url)) {
      setSelectedJobs(selectedJobs.filter((item) => item !== url));
    } else {
      setSelectedJobs([...selectedJobs, url]);
    }
  };

  const handleBlogCheckboxChange = (url) => {
    if (selectedJobs.includes(url)) {
      setSelectedBlogs(selectedBlogs.filter((item) => item !== url));
    } else {
      setSelectedBlogs([...selectedBlogs, url]);
    }
  };
  const handleSelectAllPages = () => {
    if (selectAllPages) {
      setSelectedPages([]);
    } else {
      const allPageUrls = routesConfig.map((page) => page.url);
      setSelectedPages(allPageUrls);
    }
    setSelectAllPages(!selectAllPages);
  };
  const handleSelectAllJobs = () => {
    if (selectAllJobs) {
      setSelectedJobs([]);
    } else {
      const allJobUrls = jobs.map((job) => job.url);
      setSelectedJobs(allJobUrls);
    }
    setSelectAllJobs(!selectAllJobs);
  };

  const handleSelectAllBlogs = () => {
    if (selectAllBlogs) {
      setSelectedBlogs([]);
    } else {
      const allJobUrls = blogs.map((job) => job.url);
      setSelectedBlogs(allJobUrls);
    }
    setSelectAllBlogs(!selectAllBlogs);
  };
  const { openPopup } = usePopup();
  // const [popupComponent, setPopupComponent] = useState(null);
  const handleClosePopup = (message) => {
    //setPopupComponent(null);
    setSelectedPages([]);
    setSelectedJobs([]);
    setSelectAllJobs(false);
    setSelectAllPages(false);
    openPopup();
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const allPages = routesConfig.map((page) => page.url);
    const allJobs = jobs.map((job) => job.url);

    const unselectedPages = allPages.filter(
      (page) => !selectedPages.includes(page)
    );
    const unselectedJobs = allJobs.filter((job) => !selectedJobs.includes(job));

    const data = {
      unselectedJobs,
      unselectedPages,
    };

    try {
      const response = await api.createSitemap(data);

      if (response.status === 200) {
        setLoading(false);
        setTimeout(() => {
          const popup = (
            <SiteMapPopup
              onClose={() => handleClosePopup("Popup closed")}
              data={response?.xmlContent}
            />
          );
          // //setPopupComponent(popup);
          openPopup(popup);
        }, 1000);
      } else {
        console.error("Error creating sitemap:", response.message);

        toastRef.current.addToast("Failed to create sitemap", "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error creating sitemap:", error);

      toastRef.current.addToast(
        "An error occurred while creating sitemap",
        "error"
      );
      setLoading(false);
    }
  };

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between">
          <h2
            className="text-2xl font-bold mb-4 flex items-center justify-center"
            style={{ color: "#003366", width: "100%" }}
          >
            Manage Site Map
          </h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="flex">
            <div>
              <h3
                className="text-lg font-bold mb-4"
                style={{ color: "#003366" }}
              >
                Website Pages
              </h3>
              <div className="mb-2">
                <label
                  className="flex items-center font-semibold"
                  style={{ color: "#003366" }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllPages}
                    onChange={handleSelectAllPages}
                    className="mr-2"
                  />
                  Select All Pages
                </label>
              </div>
              {routesConfig.map((page) => (
                <div key={page.id} className="mb-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      value={page.url}
                      checked={selectedPages.includes(page.url)}
                      onChange={() => handlePageCheckboxChange(page.url)}
                      className="mr-2"
                    />
                    <a
                      href={page.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline relative"
                      style={{ zIndex: 10 }}
                    >
                      {page.name}
                    </a>
                  </label>
                  <span
                    className="absolute text-xs text-gray-600 bg-gray-100 p-1 rounded opacity-0 hover:opacity-100 transition-opacity"
                    style={{ left: "105%", top: "0", zIndex: 20 }}
                  >
                    {page.url}
                  </span>
                </div>
              ))}
            </div>
            <div className="ml-8">
              <h3
                className="text-lg font-bold mb-4"
                style={{ color: "#003366" }}
              >
                Active Jobs
              </h3>
              <div className="mb-2">
                <label
                  className="flex items-center font-semibold"
                  style={{ color: "#003366" }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllJobs}
                    onChange={handleSelectAllJobs}
                    className="mr-2"
                  />
                  Select All Jobs
                </label>
              </div>
              {jobs.map((job) => (
                <div key={job.id} className="mb-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      value={job.url}
                      checked={selectedJobs.includes(job.url)}
                      onChange={() => handleJobCheckboxChange(job.url)}
                      className="mr-2"
                    />
                    <a
                      href={job.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline relative"
                      style={{ zIndex: 10 }}
                    >
                      {job.title}
                    </a>
                  </label>
                  <span
                    className="absolute text-xs text-gray-600 bg-gray-100 p-1 rounded opacity-0 hover:opacity-100 transition-opacity"
                    style={{ left: "105%", top: "0", zIndex: 20 }}
                  >
                    {job.url}
                  </span>
                </div>
              ))}
              <button
                type="button"
                onClick={() => setPage((prevPage) => prevPage + 1)} // Increment page
                className="mt-4 px-4 py-2 rounded-full bg-blue-600 text-white"
              >
                Load More Jobs
              </button>
            </div>

            <div className="ml-8">
              <h3
                className="text-lg font-bold mb-4"
                style={{ color: "#003366" }}
              >
                Active Blogs
              </h3>
              <div className="mb-2">
                <label
                  className="flex items-center font-semibold"
                  style={{ color: "#003366" }}
                >
                  <input
                    type="checkbox"
                    checked={selectAllBlogs}
                    onChange={handleSelectAllBlogs}
                    className="mr-2"
                  />
                  Select All Blogs
                </label>
              </div>
              {blogs.map((blog) => (
                <div key={blog.id} className="mb-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      value={blog.url}
                      checked={selectedBlogs.includes(blog.url)}
                      onChange={() => handleBlogCheckboxChange(blog.url)}
                      className="mr-2"
                    />
                    <a
                      href={blog.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline relative"
                      style={{ zIndex: 10 }}
                    >
                      {blog.title}
                    </a>
                  </label>
                  <span
                    className="absolute text-xs text-gray-600 bg-gray-100 p-1 rounded opacity-0 hover:opacity-100 transition-opacity"
                    style={{ left: "105%", top: "0", zIndex: 20 }}
                  >
                    {blog.url}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-between gap-3 mt-5">
            <button
              className="hrp-find-course-btn px-8 py-2 rounded-full"
              type="submit"
            >
              Generate Sitemap
            </button>
          </div>
        </form>
      </div>
      {loading && (
        <div className="RoadmapSpinnerDiv" id="RoadmapSpinnerDiv">
          <span className="RoadmapSpinner"></span>
        </div>
      )}
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default Sitemap;
