// App.js
import React, { useState, useEffect } from "react";
import "../src/css/responcive.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import "./App.css";
import { getUserRole } from "./helpers/utils.js";
import "./components/Layout/menubaar.css";
import ApplicantLayout from "./components/Layout/ApplicantLayout.js";
import EmployerLayout from "./components/Layout/EmployerLayout.js";
import Websitelayout from "./components/Layout/Websitelayout.js";
import IframeLayout from "./components/Layout/IframeLayout.js";
import SellerCourse from "./components/Layout/SellerLayout.js";
import SuperAdminLayout from "./components/Layout/SuperAdminLayout.js";
import NotFound from "./components/Website/NotFound.js";
import PermissionDenied from "./components/Website/PermissionDenied.js";
import GoogleCallback from "./components/Website/googleCallback.js";

const App = () => {
  const userRole = getUserRole();
  const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!userRole) {
          return <Redirect to="/" />; // Redirect if not logged in
        }
        if (allowedRoles.includes(userRole)) {
          return <Component {...props} />;
        } else {
          return <Redirect to="/permission-denied" />;
        }
      }}
    />
  );
  return (
    <Router>
      <Switch>
        {/* Role-Based Routing */}
        <Route path="/google/callback" component={GoogleCallback} />
        <Route
          path="/dashboard"
          render={(props) => {
            // Render appropriate layout based on role
            if (userRole === "applicant") {
              return <ApplicantLayout {...props} />;
            }
            if (userRole === "superadmin") {
              return <SuperAdminLayout {...props} />;
            }
            if (userRole === "employer") {
              return <EmployerLayout {...props} />;
            }
            if (userRole === "staff") {
              return <EmployerLayout {...props} />;
            }
            if (userRole === "seller") {
              return <SellerCourse {...props} />;
            }
            // If none of the roles match, redirect to permission denied or some fallback
            return <PermissionDenied />;
          }}
        />

        {/* Websitelayout for all other routes */}
        <Route
          path="/"
          render={(props) => {
            // Parse query parameters
            const queryParams = new URLSearchParams(props.location.search);
            const hasAuthToken = queryParams.get("authtoken");
            console.log(hasAuthToken, "hasAuthToken");

            // Conditionally render the component
            if (hasAuthToken) {
              return <IframeLayout {...props} />;
            }
            return <Websitelayout {...props} />;
          }}
        />

        {/* Permission Denied Page */}
        <Route path="/permission-denied" component={PermissionDenied} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route path="/404" component={NotFound} />
        {/* Catch-all route for 404 - Page Not Found */}
        <Route component={() => <Redirect to="/404" />} />
      </Switch>
    </Router>
  );
};

export default App;
