import React, { useState, useEffect } from "react";
import axios from "axios";
import { getUserData } from "../../helpers/utils";
import IframeSection from "../IframeSection";

const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

const GenerateToken = () => {
  const User = getUserData(); // Get the logged-in user's information
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false); // State for copy success message

  const email = User?.email; // Logged-in user's email

  // Validate existing token
  const validateToken = async (token) => {
    try {
      const response = await axios.post(`${domainpath}/api/validate-token`, {
        token,
      });
      return response.data.isValid; // Assume the backend returns `isValid: true/false`
    } catch (error) {
      return false;
    }
  };

  // Fetch the existing token for the logged-in user
  const fetchExistingToken = async () => {
    if (!email) return; // If no email is available, do nothing
    setMessage("");
    setLoading(true);

    try {
      const response = await axios.get(
        `${domainpath}/api/get-token?email=${email}`
      );
      const existingToken = response.data.authorization_token;

      if (existingToken) {
        const isValid = await validateToken(existingToken);
        if (isValid) {
          setToken(existingToken);
          setMessage("Using your existing token.");
        } else {
          setMessage(
            "Existing token is invalid. Please refresh to generate a new one."
          );
        }
      } else {
        setMessage("No existing token found. Please generate a new one.");
      }
    } catch (error) {
      setMessage("Error fetching token. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Generate a new token
  const generateToken = async () => {
    if (!email) return; // If no email is available, do nothing
    setMessage("");
    setLoading(true);

    try {
      const response = await axios.post(`${domainpath}/api/generate-token`, {
        email,
      });
      setToken(response.data.authorization_token);
      setMessage("Token generated successfully (valid for 1 years).");
    } catch (error) {
      setMessage("Error generating token. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch the token when the component mounts or the user changes
  useEffect(() => {
    if (email) fetchExistingToken();
  }, [email]);

  // Copy token to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(token).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 Generate-Token">
      <div className="bg-white shadow-xl rounded-lg p-8 max-w-2xl w-full">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
          Authorisation Token
        </h2>
        {email ? (
          <>
            {message && (
              <div className="mt-4 text-center">
                <p
                  className={`text-sm font-medium ${
                    token ? "text-green-600" : "text-red-600"
                  }`}
                >
                  {message}
                </p>
              </div>
            )}

            {token ? (
              <div className="mt-4">
                <label
                  htmlFor="token"
                  className="block text-sm font-medium text-gray-700"
                >
                  Your Token
                </label>
                <textarea
                  id="token"
                  value={token}
                  readOnly
                  rows="4"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm bg-gray-100"
                />
                <div className="flex items-center mt-2">
                  <button
                    onClick={copyToClipboard}
                    className="mr-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 text-sm"
                  >
                    Copy to Clipboard
                  </button>
                  {copySuccess && (
                    <span className="text-green-600 text-sm font-medium">
                      Token copied!
                    </span>
                  )}
                  <p
                    onClick={generateToken}
                    disabled={loading}
                    style={{
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                    className="flex items-center text-indigo-600 hover:text-indigo-800 text-sm font-medium"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      width="20px"
                      height="20px"
                      style={{
                        marginRight: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160 352 160c-17.7 0-32 14.3-32 32s14.3 32 32 32l111.5 0c0 0 0 0 0 0l.4 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1L16 432c0 17.7 14.3 32 32 32s32-14.3 32-32l0-35.1 17.6 17.5c0 0 0 0 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.8c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352l34.4 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L48.4 288c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
                    </svg>
                    {loading ? "Regenerating..." : "Regenerate Token"}
                  </p>
                </div>
                <IframeSection authToken={token} />
              </div>
            ) : (
              <div className="mt-6 text-center">
                <button
                  onClick={generateToken}
                  disabled={loading}
                  className="px-6 py-2 bg-indigo-600 text-white font-medium rounded-md hover:bg-indigo-700"
                >
                  {loading ? "Generating..." : "Generate Token"}
                </button>
              </div>
            )}
          </>
        ) : (
          <p className="text-center text-red-600 font-medium">
            No user logged in. Please log in to generate a token.
          </p>
        )}
      </div>
    </div>
  );
};

export default GenerateToken;
