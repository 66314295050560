import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomToastContainer from "../Common/CustomToastContainer";
import api from "../../Api";
import FeatherIcon from "feather-icons-react";
import CustomDataTable from "../Common/Customsdatatable"; // Ensure this is correctly imported

const VisitorbyDomain = () => {
  const url = window.location.href;

  // Create a URL object
  const urlObj = new URL(url);

  // Extract the 'domain' parameter from the query string
  const domain = urlObj.searchParams.get("domain");

  const toastRef = useRef();
  const [data, setData] = useState([]);
  const [selectedVisitors, setSelectedVisitors] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const history = useHistory();

  useEffect(() => {
    async function fetchVisitors() {
      try {
        const response = await api.getVisitorByDomainData(domain);
        console.log(response);

        if (response) {
          setData(response);
          setTotalRows(response.total || response.length);
        }
      } catch (error) {
        console.error("Error fetching visitors", error);
      }
    }
    if (domain) {
      fetchVisitors();
    }
  }, [domain, page, perPage]);

  const handleDelete = async (id) => {
    try {
      const response = await api.deleteVisitor(id);
      if (response.status === 200) {
        toastRef.current.addToast("Visitor deleted successfully", "success");
        setData((prev) => prev.filter((visitor) => visitor._id !== id));
        setTotalRows((prev) => prev - 1);
      } else {
        toastRef.current.addToast("Failed to delete visitor", "error");
      }
    } catch (error) {
      console.error("Error deleting visitor:", error);
      toastRef.current.addToast("An error occurred while deleting", "error");
    }
  };

  const handleBulkDelete = async () => {
    if (selectedVisitors.length === 0) {
      toastRef.current.addToast("No visitors selected", "warning");
      return;
    }
    try {
      const response = await api.deleteVisitors({ ids: selectedVisitors });
      if (response.status === 200) {
        toastRef.current.addToast("Selected visitors deleted", "success");
        setData((prev) =>
          prev.filter((visitor) => !selectedVisitors.includes(visitor._id))
        );
        setTotalRows((prev) => prev - selectedVisitors.length);
        setSelectedVisitors([]);
      } else {
        toastRef.current.addToast("Failed to delete visitors", "error");
      }
    } catch (error) {
      console.error("Error deleting visitors:", error);
      toastRef.current.addToast("An error occurred while deleting", "error");
    }
  };

  const handleRowSelect = (row) => {
    setSelectedVisitors((prevSelected) =>
      prevSelected.includes(row._id)
        ? prevSelected.filter((id) => id !== row._id)
        : [...prevSelected, row._id]
    );
  };

  const handleSelectAll = (isSelected) => {
    setSelectedVisitors(isSelected ? data.map((visitor) => visitor._id) : []);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={(e) => handleSelectAll(e.target.checked)}
          checked={
            selectedVisitors.length > 0 &&
            selectedVisitors.length === data.length
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedVisitors.includes(row._id)}
          onChange={() => handleRowSelect(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "IP Address",
      selector: (row) => (
        <p
          className="text-blue-500 underline cursor-pointer"
          onClick={() => history.push(`/dashboard/visitors-history/${row.ip}`)}
        >
          {row.ip}
        </p>
      ),
      sortable: true,
    },
    { name: "Referer", selector: (row) => row.referer, sortable: true },
    {
      name: "Date",
      selector: (row) => new Date(row.date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Time Spent",
      selector: (row) => `${row.timeSpent} sec`,
      sortable: true,
    },
    { name: "URL", selector: (row) => row.url, sortable: true },
    // { name: "Site", selector: (row) => row.site, sortable: true },
    // { name: "Page URL", selector: (row) => row.pageUrl, sortable: true },
    { name: "Clicks", selector: (row) => row.clicks, sortable: true },
    { name: "Latitude", selector: (row) => row.lat, sortable: true },
    { name: "Longitude", selector: (row) => row.long, sortable: true },
    // {
    //   name: "Actions",
    //   cell: (row) => (
    //     <button
    //       className="manage-delete-btn text-white px-6 py-2"
    //       onClick={() => handleDelete(row._id)}
    //     >
    //       <FeatherIcon icon="trash" />
    //     </button>
    //   ),
    // },
  ];

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold" style={{ color: "#003366" }}>
            Visitors for {domain}
          </h2>
          {selectedVisitors.length > 0 && (
            <button
              className="manage-delete-btn text-white px-6 py-2"
              onClick={handleBulkDelete}
            >
              Delete Selected
            </button>
          )}
        </div>
        <CustomDataTable
          columns={columns}
          data={data}
          totalRows={totalRows}
          onPageChange={setPage}
          onRowsPerPageChange={setPerPage}
          currentPage={page}
        />
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default VisitorbyDomain;
