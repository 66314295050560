import React, { useState } from "react";
import Sidebar from "../Sidebar";
import ApiSection from "../ApiSection";
const domainpath = process.env.REACT_APP_DOMAIN;
const Documentation = () => {
  const [authToken, setAuthToken] = useState("");
  const pluginFilePath = `${domainpath}/plugins.zip`;
  const [activeSection, setActiveSection] = useState("authorisation");
  var jobresp = {
    _id: "673d90111f9b0f93a4ff2105",
    title: "AI Prompt Writer",
    industry: "Information Technology",
    description:
      "This job involves generating AI prompts for various applications within the Information Technology industry.",
    location: {
      lat: null,
      long: null,
      type: "On-Site",
      city: "London",
      area: "Greater London",
      pincode: "WC1A",
      address: "123 AI Street, London",
    },
    jobType: ["Full-Time"],
    Key_responsibility: [
      "Develop AI prompts for automated responses using advanced algorithms.",
      "Create scenarios and dialogues to enhance AI learning capabilities.",
      "Collaborate with software developers to integrate AI prompt solutions into existing systems.",
      "Monitor and analyse AI prompt performance",
      "making necessary improvements.",
      "Stay updated with AI technologies and trends to implement cutting-edge prompt generation techniques.",
      "Conduct quality assurance tests on AI prompt outputs.",
      "Troubleshoot and debug AI prompt functionalities.",
      "Ensure data privacy and security measures are implemented in AI prompt systems.",
      "Provide technical support and training to end-users on AI prompt usage.",
      "Contribute to the continuous improvement of AI prompt systems.",
    ],
    categories: [
      "Software Development",
      "Network Administration",
      "Database Management",
      "Cybersecurity",
      "Data Analysis",
      "IT Support",
    ],
    tools: [],
    hoursPerWeek: "hours/week",
    shift: ["Monday to Friday", "Day Shift", "Night Shift", "Overtime"],
    numberOfPositions: 1,
    skills: [
      "Natural Language Processing (NLP)",
      "Machine Learning",
      "Python Programming",
      "Data Analysis",
      "Problem-solving",
      "Technical Writing",
      "AI Algorithms",
      "Cloud Computing",
      "Communication Skills",
      "Critical Thinking",
    ],
    educationLevel: [
      {
        qualification: "Education",
        details: [
          {
            details:
              "A Bachelor’s degree in Computer Science, Artificial Intelligence, or a related field is preferred.",
            level_of_qualification: "Level 6 (Bachelor’s Degree)",
            _id: "673d9128e35405bb4329a856",
          },
          {
            details: "Relevant A-levels or Diploma in IT disciplines.",
            level_of_qualification: "Level 3-4 (A-Level / Diploma)",
            _id: "673d9128e35405bb4329a857",
          },
        ],
        _id: "673d9128e35405bb4329a855",
      },
      {
        qualification: "Experience",
        details: [
          {
            details:
              "Previous experience in AI prompt generation or similar roles within the IT industry.",
            level_of_qualification: "3-5 years of relevant experience",
            _id: "673d9128e35405bb4329a859",
          },
        ],
        _id: "673d9128e35405bb4329a858",
      },
      {
        qualification: "Industry Knowledge",
        details: [
          {
            details:
              "Understanding of AI technologies, machine learning models, and natural language processing.",
            level_of_qualification: "Intermediate to Advanced",
            _id: "673d9128e35405bb4329a85b",
          },
        ],
        _id: "673d9128e35405bb4329a85a",
      },
      {
        qualification: "Analytical Skills",
        details: [
          {
            details:
              "Ability to analyse AI prompt data and derive meaningful insights.",
            level_of_qualification: "Advanced",
            _id: "673d9128e35405bb4329a85d",
          },
        ],
        _id: "673d9128e35405bb4329a85c",
      },
      {
        qualification: "Communication Skills",
        details: [
          {
            details:
              "Excellent verbal and written communication skills for effective collaboration.",
            level_of_qualification: "Advanced",
            _id: "673d9128e35405bb4329a85f",
          },
        ],
        _id: "673d9128e35405bb4329a85e",
      },
      {
        qualification: "Time Management",
        details: [
          {
            details:
              "Efficiently manage time to meet project deadlines and deliverables.",
            level_of_qualification: "Advanced",
            _id: "673d9128e35405bb4329a861",
          },
        ],
        _id: "673d9128e35405bb4329a860",
      },
      {
        qualification: "Problem-solving Skills",
        details: [
          {
            details:
              "Ability to resolve complex issues related to AI prompt generation.",
            level_of_qualification: "Advanced",
            _id: "673d9128e35405bb4329a863",
          },
        ],
        _id: "673d9128e35405bb4329a862",
      },
      {
        qualification: "Creativity",
        details: [
          {
            details: "Innovate and design creative AI prompt solutions.",
            level_of_qualification: "Intermediate to Advanced",
            _id: "673d9128e35405bb4329a865",
          },
        ],
        _id: "673d9128e35405bb4329a864",
      },
      {
        qualification: "Teamwork",
        details: [
          {
            details:
              "Collaborative approach to work with multidisciplinary teams.",
            level_of_qualification: "Intermediate",
            _id: "673d9128e35405bb4329a867",
          },
        ],
        _id: "673d9128e35405bb4329a866",
      },
      {
        qualification: "Adaptability",
        details: [
          {
            details: "Flexible mindset to adapt to evolving AI technologies.",
            level_of_qualification: "High",
            _id: "673d9128e35405bb4329a869",
          },
        ],
        _id: "673d9128e35405bb4329a868",
      },
    ],
    experienceLevel: [],
    employmentEligibility: [],
    applicationDeadline: "2024-12-20T00:00:00.000Z",
    applicationMethod: ["Online Application, Telephone"],
    pay: {
      minimum: "20000.00",
      maximum: "25000.00",
      rate: "NaN",
      rateType: "Monthly",
    },
    supplementalPay: ["Bonus scheme"],
    benefits: [
      "Flexible working hours",
      "Health insurance",
      "Professional development opportunities",
      "Paid time off",
      "Performance bonuses",
    ],
    company: {
      name: "IT Tech",
      logo: "https://example.com/ittech_logo.png",
      description:
        "IT Tech is a leading technology company specialising in AI solutions.",
    },
    tags: ["AI", "Technology", "IT Solutions", "Artificial Intelligence"],
    status: "Publish",
    postedDate: "2024-11-20T00:00:00.000Z",
    __v: 2,
  };
  const steps = {
    authorisation: [
      {
        text: "If you do not have an authorisation token, click on 'Generate Authorisation Token' to proceed.",
        // image: `${domainpath}/documentation/auth1.png`,
      },
      {
        text: "Navigate to the Settings menu and select 'Authorisation Token'. Click 'Generate Token' to create a new token. If you already have an authorisation code, skip this step and proceed to the next one.",
        image: `${domainpath}/documentation/auth7.png`,
      },
      {
        text: "Copy your authorisation code and use it to validate your token on the API Documentation page.",
        image: `${domainpath}/documentation/auth8.png`,
      },
    ],
    validatetoken: [
      {
        text: "Open the desired request, set the request body, and click 'Execute' to complete the process.",
        // image: `${domainpath}/documentation/auth3.png`,
      },
    ],
    jobs: {
      "jobs-all": [
        {
          text: "Retrieve a list of all jobs using the 'Retrieve Jobs' API. Add your authorisation code in the header and click 'Execute'.",
          // image: `${domainpath}/documentation/jobs1.png`,
        },
      ],
      "jobs-single": [
        {
          text: "Fetch details of a specific job by providing the Job ID in the 'Job Details' API. Add your authorisation code and execute the request.",
          // image: `${domainpath}/documentation/jobs2.png`,
        },
      ],
      "jobs-search": [
        {
          text: "Search for jobs using filters such as title, skills, and other criteria in the 'Search Jobs' API. Add your authorisation code and execute the request.",
          // image: `${domainpath}/documentation/jobs3.png`,
        },
      ],
    },
    wordpress: [
      {
        text: "Download the WordPress plugin by clicking on 'Download Plugin'.",
        // image: `${domainpath}/documentation/wordstep1.png`,
      },
      {
        text: "Install the downloaded plugin in your WordPress dashboard. Navigate to 'Plugins' > 'Add New'",
        image: `${domainpath}/documentation/wordstep2.png`,
      },
      {
        text: "'Upload Plugin' to complete the installation.",
        image: `${domainpath}/documentation/wordstep3.png`,
      },
      {
        text: "Configure the plugin settings as per your requirements. Save the changes before using the plugin.",
        image: `${domainpath}/documentation/wordstep4.png`,
      },
    ],
    "embed-code": [
      {
        text: "Customise the plugin settings as per your requirements in the embed code section. Copy the code and integrate it where necessary.",
        image: `${domainpath}/documentation/embed1.png`,
      },
    ],
  };
  return (
    <div style={{ background: "#f1f4f7" }} className="flex mainapiDoc">
      {/* API Section */}
      <div className="flex-none w-96">
        <Sidebar
          setActiveSection={setActiveSection}
          activeSection={activeSection}
        />
      </div>
      <div className="p-6 flex-1  max-w-6xl  api-doc mx-auto overflow-scroll overflow-x-hidden stepsinstrunction custom-scrollbar">
        <div className="instructions bg-gray-100 p-4 rounded-md mb-6">
          <h2 className="text-xl font-bold mb-4">
            {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}{" "}
            Instructions
          </h2>
          {activeSection === "authorisation" ? (
            <ul className=" ">
              {steps[activeSection].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>{" "}
                    <div className="text-center">
                      {step.image && (
                        <img
                          src={step.image}
                          alt={`Step ${index + 1}`}
                          className="my-2 w-full"
                        />
                      )}
                    </div>
                  </li>
                  {index == 0 ? (
                    <div className="bg-gray-50 p-6 rounded-lg mb-6 shadow-lg">
                      <p>
                        You Don't Have an Authorisation Token Click on below
                        link to generate a Authorisation token
                      </p>
                      <a
                        href="/dashboard/generate-token"
                        target="_blank"
                        style={{ color: "blue" }}
                      >
                        {domainpath}/dashboard/generate-token
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </ul>
          ) : (
            ""
          )}
          {activeSection === "validatetoken" ? (
            <ul className=" ">
              {steps[activeSection].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>
                    <div className="text-center">
                      {step.image && (
                        <img
                          src={step.image}
                          alt={`Step ${index + 1}`}
                          className="my-2 w-full"
                        />
                      )}
                    </div>
                  </li>

                  <ApiSection
                    method="POST"
                    authToken={authToken}
                    url="/validate-token"
                    description="Validate Authorisation Token."
                    exampleResponses={{
                      success: {
                        success: true,
                        isValid: true,
                      },
                      error: { message: "false", error: "Token is required" },
                    }}
                    statusDescriptions={{
                      200: "OK - The request was successful.",
                      400: "Unauthorized - Token is required",
                      401: "Unauthorized - Invalid or expired token ",
                      500: "Internal Server Error - Something went wrong.",
                    }}
                  />
                </>
              ))}
            </ul>
          ) : (
            ""
          )}

          {activeSection === "jobs-all" && (
            <ul className=" ">
              {steps.jobs["jobs-all"].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>
                    {step.image && (
                      <img
                        src={step.image}
                        alt={`Step ${index + 1}`}
                        className="my-2 w-full"
                      />
                    )}
                  </li>
                  <ApiSection
                    method="GET"
                    url="/jobs"
                    authToken={authToken}
                    description="Retrieve all published jobs."
                    queryParams={{ industry: "IT", page: 1, limit: 20 }}
                    exampleResponses={{
                      success: {
                        totalRecords: 10,
                        jobsData: [jobresp],
                      },
                      error: {
                        message: false,
                        error: "No token provided.",
                      },
                    }}
                    statusDescriptions={{
                      200: "OK - The request was successful.",
                      401: "Unauthorized - Invalid or missing authentication token.",
                      500: "Internal Server Error - Something went wrong.",
                    }}
                  />
                </>
              ))}
            </ul>
          )}

          {/* Single Job Section */}
          {activeSection === "jobs-single" && (
            <ul className=" ">
              {steps.jobs["jobs-single"].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>
                    {step.image && (
                      <img
                        src={step.image}
                        alt={`Step ${index + 1}`}
                        className="my-2 w-full"
                      />
                    )}
                  </li>
                  <ApiSection
                    method="GET"
                    url="/jobs/:id"
                    jobid="jobid"
                    description="Retrieve detailed information about a specific job by its ID."
                    authToken={authToken}
                    exampleResponses={{
                      success: jobresp,
                      error: {
                        message: "Invalid or missing authentication token",
                        success: "false",
                      },
                    }}
                    statusDescriptions={{
                      200: "OK - The request was successful.",
                      400: "Unauthorized - Invalid Job Id.",
                      401: "Unauthorized - Invalid or missing authentication token.",
                      500: "Internal Server Error - Something went wrong.",
                    }}
                  />
                </>
              ))}
            </ul>
          )}
          {/* Search Jobs Section */}
          {activeSection === "jobs-search" && (
            <ul className=" ">
              {steps.jobs["jobs-search"].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>
                    {step.image && (
                      <img
                        src={step.image}
                        alt={`Step ${index + 1}`}
                        className="my-2 w-full"
                      />
                    )}
                  </li>

                  <ApiSection
                    method="GET"
                    url="/search-jobs"
                    description="Search for jobs using filters like title, skills, and more."
                    authToken={authToken}
                    queryParams={{
                      title: "engineer",
                      skills: "JavaScript,Node.js",
                      page: 1,
                      limit: 20,
                    }}
                    exampleResponses={{
                      success: {
                        totalRecords: 10,
                        jobsData: [jobresp],
                      },
                      error: { message: false, error: "No token provided." },
                    }}
                    statusDescriptions={{
                      200: "OK - The request was successful.",
                      401: "Unauthorized - Invalid or missing authentication token.",
                      500: "Internal Server Error - Something went wrong.",
                    }}
                  />
                </>
              ))}
            </ul>
          )}

          {activeSection === "wordpress" ? (
            <ul className=" ">
              {steps[activeSection].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>
                    {step.image && (
                      <img
                        src={step.image}
                        alt={`Step ${index + 1}`}
                        className="my-2 w-full"
                      />
                    )}
                  </li>
                  {index == 0 ? (
                    <div className="mt-8 bg-gray-50 p-6 rounded-lg mb-6 shadow-lg">
                      <h3 className="text-xl font-bold text-gray-800 mb-4">
                        WordPress Plugins
                      </h3>
                      <p className="text-gray-700 mb-4">
                        You can download our WordPress plugin by clicking the
                        button below:
                      </p>
                      <a
                        href={pluginFilePath}
                        download="sample-plugin.zip" // File name for the download
                        className="bg-blue-500 text-white px-6 py-3 rounded-md shadow hover:bg-blue-600 text-lg font-semibold downloadplugin"
                      >
                        Download Plugin
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </ul>
          ) : (
            ""
          )}
          {activeSection === "embed-code" ? (
            <ul className=" ">
              {steps[activeSection].map((step, index) => (
                <>
                  <li key={index} className="mb-4 text-lg">
                    <p>{step.text}</p>
                    {step.image && (
                      <img
                        src={step.image}
                        alt={`Step ${index + 1}`}
                        className="my-2 w-full"
                      />
                    )}
                  </li>
                </>
              ))}
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Documentation;
