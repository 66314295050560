import React, { useState } from "react";

const ChartComponent = ({ jobViews }) => {
  const [hoveredPoint, setHoveredPoint] = useState(null);

  // Default chart data for testing
  const data = jobViews?.data || [0, 150, 300, 500]; // Example data
  const labels = jobViews?.labels || [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
  ];
  const period = jobViews?.period || "daily";

  // Dynamically calculate max Y value with some buffer
  const maxDataValue = Math.max(...data);
  const yAxisValues = [0, 100, 200, 300, 400, 500];
  const maxValue = Math.max(maxDataValue, ...yAxisValues) + 50; // Buffer at the top

  const svgWidth = 400;
  const svgHeight = 200;
  const pointGap =
    labels.length > 1 ? svgWidth / (labels.length - 1) : svgWidth;

  // Generate the path using Bézier curves
  const generatePath = () => {
    if (data.length < 2) return "";

    let path = `M0,${svgHeight - (data[0] / maxValue) * svgHeight}`;

    for (let i = 0; i < data.length - 1; i++) {
      const x1 = i * pointGap;
      const y1 = svgHeight - (data[i] / maxValue) * svgHeight;

      const x2 = (i + 1) * pointGap;
      const y2 = svgHeight - (data[i + 1] / maxValue) * svgHeight;

      const controlX1 = x1 + pointGap / 2;
      const controlY1 = y1;
      const controlX2 = x2 - pointGap / 2;
      const controlY2 = y2;

      path += ` C${controlX1},${controlY1} ${controlX2},${controlY2} ${x2},${y2}`;
    }

    return path;
  };

  return (
    <div className="relative w-full h-64">
      <svg
        viewBox={`0 -10 ${svgWidth} ${svgHeight + 50}`}
        className="w-full h-full"
      >
        {/* Y-Axis Grid Lines */}
        {yAxisValues.map((value, index) => (
          <line
            key={index}
            x1="0"
            y1={svgHeight - (value / maxValue) * svgHeight}
            x2={svgWidth}
            y2={svgHeight - (value / maxValue) * svgHeight}
            stroke="#e5e7eb"
            strokeWidth="1"
          />
        ))}

        {/* Y-Axis Labels */}
        {yAxisValues.map((value, index) => (
          <text
            key={index}
            x="-10"
            y={svgHeight - (value / maxValue) * svgHeight + 4}
            textAnchor="end"
            className="text-gray-500 text-xs"
          >
            {value}
          </text>
        ))}

        {/* Gradient Fill */}
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="orange" stopOpacity="0.3" />
            <stop offset="100%" stopColor="orange" stopOpacity="0" />
          </linearGradient>
        </defs>

        {/* Area Path */}
        <path
          d={`M0,${svgHeight} ${generatePath()} L${svgWidth},${svgHeight} Z`}
          fill="url(#gradient)"
        />

        {/* Line Path */}
        <path
          d={generatePath()}
          fill="none"
          stroke="orange"
          strokeWidth="2"
          strokeLinecap="round"
        />

        {/* Data Points */}
        {data.map((value, index) => {
          const cx = index * pointGap;
          const cy = svgHeight - (value / maxValue) * svgHeight;

          return (
            <g
              key={index}
              onMouseEnter={() => setHoveredPoint({ value, x: cx, y: cy })}
              onMouseLeave={() => setHoveredPoint(null)}
            >
              <circle
                cx={cx}
                cy={cy}
                r="4"
                fill="orange"
                className="shadow-md"
              />
              <title>{value}</title>
            </g>
          );
        })}

        {/* X-Axis Labels */}
        {labels.map((label, index) => {
          const x = index * pointGap;
          return (
            <text
              key={index}
              x={x}
              y={svgHeight + 20}
              textAnchor="middle"
              className="text-gray-500 text-xs"
            >
              {label}
            </text>
          );
        })}
      </svg>

      {/* Hover Tooltip */}
      {hoveredPoint && (
        <div
          style={{
            position: "absolute",
            left: hoveredPoint.x,
            top: hoveredPoint.y,
            transform: "translate(-50%, -100%)",
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            color: "white",
            padding: "4px 8px",
            borderRadius: "4px",
            pointerEvents: "none",
            fontSize: "12px",
          }}
        >
          {hoveredPoint.value}
        </div>
      )}

      {/* Period Description */}
      <div className="mt-4 text-center">
        <p className="text-sm text-gray-600">
          Showing data for the <span className="font-medium">{period}</span>{" "}
          period.
        </p>
      </div>
    </div>
  );
};

export default ChartComponent;
