import React, { useEffect, useState } from "react";
import image1 from "../../img/employeebg.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import nextSectionImage from "../../img/nextsectionimg.png";
import imgclient from "../../img/Ellipse.png";
import Employerpageimage from "../../img/employerpageimage.png";

import api from "../../Api.js";
import { usePopup } from "../Common/PopupContext";
import { useHistory } from "react-router-dom";
import Vectorcandidate from "../../img/Vectoremployer.png";
import VisitorTracker from "../Common/VisitorTracker.js";
import Stepfour from "../../image/fluent_communication-16-regular.png";
import Stepfive from "../../image/fluent-mdl2_onboarding.png";
import Login from "../Website/Login";
import AOS from "../../helpers/AOS.js";
import CountUp from "../../helpers/CountUp";
const elvy = process.env.PUBLIC_URL + "/elvy.jpg";
const cath = process.env.PUBLIC_URL + "/cath.jpg";
const tania = process.env.PUBLIC_URL + "/tania.png";
const borro = process.env.PUBLIC_URL + "/borro.png";
const alex = process.env.PUBLIC_URL + "/alex.jpg";
const Employers = () => {
  const history = useHistory();
  const [data, setData] = useState({
    totalJobs: 0,
    totalApplicants: 0,
    totalCompanies: 0,
    totalActiveResumes: 0,
  });
  const testimonials = [
    {
      id: 1,
      name: "Elvy",
      position: "Managers",
      image: elvy,
      quote:
        "The job portal has been an invaluable tool for our hiring needs. The user-friendly interface and powerful candidate filtering options have saved us hours in the recruitment process.",
    },
    {
      id: 2,
      name: "Catherine",
      position: "PA of Director",
      image: cath,
      quote:
        "We've found some of our best hires through this portal! The intuitive design and seamless candidate communication features have simplified our hiring workflow tremendously.",
    },
    {
      id: 3,
      name: "Tania",
      position: "Relationship Manager",
      image: tania,
      quote:
        "Our experience has been remarkable. The advanced analytics and insightful recommendations make it easy to find qualified candidates who match our job requirements precisely.",
    },
    {
      id: 4,
      name: "Borromeo",
      position: "Customer Service Associate",
      image: borro,
      quote:
        "The portal has streamlined our job search, connecting us with top talent efficiently. The smooth application process and effective candidate matching have made hiring straightforward!",
    },
    {
      id: 5,
      name: "Alex",
      position: "Office Administrator",
      image: alex,
      quote:
        "An outstanding experience! The intuitive interface and advanced search features have helped us find skilled candidates quickly. It's now our primary platform for recruitment.",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  const submitform = (formId) => {
    const formData = {};
    const form = document.getElementById(formId);

    for (var i = 0; i < form.elements.length; i++) {
      var element = form.elements[i];

      if (element.name && element.type !== "submit") {
        var nameParts = element.name.split("[");

        if (nameParts.length > 1) {
          var currentObj = formData;

          for (var j = 0; j < nameParts.length - 1; j++) {
            var key = nameParts[j].replace("]", "");
            if (!currentObj[key]) {
              currentObj[key] = {};
            }
            currentObj = currentObj[key];
          }
          var key = nameParts[nameParts.length - 1].replace("]", "");

          if (key === "tags") {
            if (!currentObj[key]) {
              currentObj[key] = [];
            }
            currentObj[key] = currentObj[key].concat(
              element.value.split(",").map((tag) => tag.trim())
            );
          } else {
            if (currentObj[key]) {
              if (!Array.isArray(currentObj[key])) {
                currentObj[key] = [currentObj[key]];
              }
              currentObj[key].push(element.value);
            } else {
              currentObj[key] = element.value;
            }
          }
        } else {
          formData[element.name] = element.value;
        }
      }
    }
    return formData;
  };
  const handleSubmit = async (e) => {
    var formData = submitform("contactus");
    e.preventDefault();
    try {
      const response = await api.contactus(formData);
    } catch (error) {}
  };
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "data:text/javascript;base64,LyogQWxsaSBBSSB3aWRnZXQgZm9yIHd3dy5oaXJlcHJvcy5jby51ayAqLwooZnVuY3Rpb24gKHcsZCxzLG8sZixqcyxmanMpIHt3WydBbGxpSlNXaWRnZXQnXT1vO3dbb10gPSB3W29dIHx8IGZ1bmN0aW9uICgpIHsgKHdbb10ucSA9IHdbb10ucSB8fCBbXSkucHVzaChhcmd1bWVudHMpIH07anMgPSBkLmNyZWF0ZUVsZW1lbnQocyksIGZqcyA9IGQuZ2V0RWxlbWVudHNCeVRhZ05hbWUocylbMF07anMuaWQgPSBvOyBqcy5zcmMgPSBmOyBqcy5hc3luYyA9IDE7IGZqcy5wYXJlbnROb2RlLmluc2VydEJlZm9yZShqcywgZmpzKTt9KHdpbmRvdywgZG9jdW1lbnQsICdzY3JpcHQnLCAnYWxsaScsICdodHRwczovL3N0YXRpYy5hbGxpYWkuY29tL3dpZGdldC92MS5qcycpKTthbGxpKCdpbml0JywgJ3NpdGVfV3RkekJOTUs2aUVORGN0eScpO2FsbGkoJ29wdGltaXplJywgJ2FsbCcpOw==";
    script.async = true;
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  /* ======= For Popup ====== */
  const { openPopup } = usePopup();

  const handleClosePopup = (message) => {
    //console.log(message);
    openPopup();
  };

  const handleClickEmployee = () => {
    const popup = (
      <Login
        onClose={() => handleClosePopup("Popup closed")}
        employee="employee"
      />
    );
    openPopup(popup);
  };
  const fetchData = async () => {
    try {
      const response = await api.getSucess();
      setData(response); // Update state with the fetched data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Use useEffect to call fetchData on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="hrp-main-section  employers-page ">
        <section className="hrp-container hrp-section-1">
          <AOS animation="fade-down">
            {" "}
            <div className="hrp-sub-section  ">
              <div className="container m-auto">
                <div className="flex flex-col md:flex-row items-center py-10  lg:px-14 md:px-10  xl:mx-10 2xl:mx-10 ">
                  <div className="md:w-1/2 mb-8 md:mb-0 hrp-Employees-outer employer-without-relying animate-left">
                    <h1
                      className="lg:text-4xl hrp-Employees-heading  font-semibold mb-4 text-[#343434]"
                      style={{
                        color: "#3E3E3E",
                      }}
                    >
                      Find and Hire the Best Employees
                    </h1>
                    <p className="xl:text-2xl text-gray-600 mb-4">
                      Join thousands of companies who trust us to build
                    </p>
                    <button
                      className="py-3 px-12 btnpostjob text-xl"
                      onClick={() => history.push("/post-job")}
                    >
                      Post a Job
                    </button>
                  </div>
                  <div className="flex md:w-1/2 justify-center animate-right">
                    <img
                      src={image1}
                      alt="Employees illustration"
                      className="w-full lg:w-full lg:w-1/4 mb-8 md:mb-0"
                    />
                  </div>
                </div>
              </div>
            </div>
          </AOS>
        </section>
        <section className="hrp-container  hrp-section-2">
          <AOS animation="slide-up">
            {" "}
            <div className="hrp-sub-section hrp-Employees-outer md:px-10">
              <div className="lg:flex flex-row-reverse md:flex-row items-center lg:py-10 container m-auto">
                <div className="flex lg:w-1/2 md:w-full mb-8 md:mb-0">
                  <img
                    src={nextSectionImage}
                    alt="Creative Agency"
                    className="rounded-lg "
                  />
                </div>
                <div className="lg:w-1/2 md:w-full px-2 lg:px-10 md:px-3">
                  <h1
                    className="lg:text-2xl font-bold mb-4 employer-Connecting-without-relying"
                    style={{ color: "#3E3E3E" }}
                  >
                    Find Top Talent Fast With HirePros
                  </h1>
                  <p
                    className="text-[#797979]-700 mb-4"
                    style={{ fontSize: "14px", color: "#797979" }}
                  >
                    Discover the perfect candidates for your company's needs
                    with HirePros. Our platform offers a comprehensive search of
                    open positions on the web, personalised salary estimates,
                    and reviews on over 600,000 companies worldwide. Whether
                    you're looking for specific skills or a cultural fit,
                    HirePros streamlines the hiring process, making it easier
                    than ever to connect with the right talent.
                  </p>
                  <ul className="list-disc lg:ml-5 list-inside mb-4 text-gray-700 w-full">
                    <li className="mb-2 2xl:flex items-start">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 inline-block"
                        style={{ stroke: "#003366" }}
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="#003366"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span
                        className="font-semibold"
                        style={{ color: "#003366", fontSize: "13px" }}
                      >
                        Efficient Matching:
                      </span>
                      <p style={{ color: "#797979", fontSize: "12px" }}>
                        Leverage Advanced Algorithms To Find Candidates Who Meet
                        Your Exact Criteria.
                      </p>
                    </li>
                    <li className="mb-2 2xl:flex items-start">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 inline-block"
                        style={{ stroke: "#003366" }}
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="#003366"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span
                        className="font-semibold"
                        style={{ color: "#003366", fontSize: "13px" }}
                      >
                        Comprehensive Insights:
                      </span>
                      <p style={{ color: "#797979", fontSize: "12px" }}>
                        Access In-Depth Reviews And Salary Estimates To Make
                        Informed Hiring Decisions.
                      </p>
                    </li>
                    <li className="mb-2 2xl:flex items-start">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 inline-block"
                        style={{ stroke: "#003366" }}
                      >
                        <path
                          d="M20 6L9 17L4 12"
                          stroke="#003366"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <span
                        className="font-semibold"
                        style={{ color: "#003366", fontSize: "13px" }}
                      >
                        User-Friendly Interface:
                      </span>
                      <p style={{ color: "#797979", fontSize: "12px" }}>
                        Enjoy A Seamless Experience From Job Posting To
                        Candidate Selection.
                      </p>
                    </li>
                  </ul>

                  <button
                    className="py-3 px-10 btnpostjob text-xl rounded-lg"
                    onClick={() => handleClickEmployee()}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>
          </AOS>
        </section>

        <section
          class=" hrp-container  hrp-section-2 my-10 hrp-Seamlessly-border"
          style={{ backgroundColor: "#F2F9FF" }}
        >
          <div class="container mx-auto px-6 md:px-12 py-10">
            <div className="text-center">
              {" "}
              <h2 class="text-3xl font-bold  mb-4" style={{ color: "#464646" }}>
                Show Jobs On Your Website
              </h2>
              <p class="text-gray-600 text-base leading-relaxed mb-8 hrp-seamlessly">
                Seamlessly integrate your platform with your website using our
                flexible tools. Whether you prefer using a simple iframe or
                tapping into our powerful API, you can provide a smooth and
                consistent experience for your visitors. Elevate your brand by
                showcasing your job listings, application processes, or other
                features directly on your site.
              </p>
            </div>
            <div class="lg:flex md:items-center  lg:gap-20 gap-5 pt-10">
              <div class="flex justify-center">
                <img
                  src={Employerpageimage}
                  alt="Job Preview"
                  class="rounded-md border border-gray-200 shadow-md mx-auto md:mx-0"
                  style={{
                    boxshadow:
                      " rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
                  }}
                />
              </div>

              <div class="lg:mt-0 mt-10">
                <ul
                  class="space-y-4 hrp-processes-section"
                  style={{ color: "#353535" }}
                >
                  <li class="flex items-start">
                    <span class="mr-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 12.0001C20 14.1218 19.1571 16.1566 17.6569 17.6569C16.1566 19.1572 14.1217 20.0001 12 20.0001C9.87827 20.0001 7.84344 19.1572 6.34315 17.6569C4.84285 16.1566 4 14.1218 4 12.0001C4 9.87833 4.84285 7.8435 6.34315 6.3432C7.84344 4.84291 9.87827 4.00006 12 4.00006C12.76 4.00006 13.5 4.11006 14.2 4.31006L15.77 2.74006C14.5745 2.24707 13.2932 1.99557 12 2.00006C10.6868 2.00006 9.38642 2.25872 8.17317 2.76126C6.95991 3.26381 5.85752 4.00041 4.92893 4.92899C3.05357 6.80436 2 9.34789 2 12.0001C2 14.6522 3.05357 17.1958 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2389C9.38642 21.7414 10.6868 22.0001 12 22.0001C14.6522 22.0001 17.1957 20.9465 19.0711 19.0711C20.9464 17.1958 22 14.6522 22 12.0001M7.91 10.0801L6.5 11.5001L11 16.0001L21 6.00006L19.59 4.58006L11 13.1701L7.91 10.0801Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </span>
                    <span class="" style={{ color: "#353535" }}>
                      Embed Your Platform With An Iframe: Copy And Paste One
                      Line Of Code.
                    </span>
                  </li>
                  <li class="flex items-start">
                    <span class="mr-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 12.0001C20 14.1218 19.1571 16.1566 17.6569 17.6569C16.1566 19.1572 14.1217 20.0001 12 20.0001C9.87827 20.0001 7.84344 19.1572 6.34315 17.6569C4.84285 16.1566 4 14.1218 4 12.0001C4 9.87833 4.84285 7.8435 6.34315 6.3432C7.84344 4.84291 9.87827 4.00006 12 4.00006C12.76 4.00006 13.5 4.11006 14.2 4.31006L15.77 2.74006C14.5745 2.24707 13.2932 1.99557 12 2.00006C10.6868 2.00006 9.38642 2.25872 8.17317 2.76126C6.95991 3.26381 5.85752 4.00041 4.92893 4.92899C3.05357 6.80436 2 9.34789 2 12.0001C2 14.6522 3.05357 17.1958 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2389C9.38642 21.7414 10.6868 22.0001 12 22.0001C14.6522 22.0001 17.1957 20.9465 19.0711 19.0711C20.9464 17.1958 22 14.6522 22 12.0001M7.91 10.0801L6.5 11.5001L11 16.0001L21 6.00006L19.59 4.58006L11 13.1701L7.91 10.0801Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </span>
                    <span class="" style={{ color: "#353535" }}>
                      API Integration: Advanced Customization And Control.
                    </span>
                  </li>
                  <li class="flex items-start">
                    <span class="mr-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20 12.0001C20 14.1218 19.1571 16.1566 17.6569 17.6569C16.1566 19.1572 14.1217 20.0001 12 20.0001C9.87827 20.0001 7.84344 19.1572 6.34315 17.6569C4.84285 16.1566 4 14.1218 4 12.0001C4 9.87833 4.84285 7.8435 6.34315 6.3432C7.84344 4.84291 9.87827 4.00006 12 4.00006C12.76 4.00006 13.5 4.11006 14.2 4.31006L15.77 2.74006C14.5745 2.24707 13.2932 1.99557 12 2.00006C10.6868 2.00006 9.38642 2.25872 8.17317 2.76126C6.95991 3.26381 5.85752 4.00041 4.92893 4.92899C3.05357 6.80436 2 9.34789 2 12.0001C2 14.6522 3.05357 17.1958 4.92893 19.0711C5.85752 19.9997 6.95991 20.7363 8.17317 21.2389C9.38642 21.7414 10.6868 22.0001 12 22.0001C14.6522 22.0001 17.1957 20.9465 19.0711 19.0711C20.9464 17.1958 22 14.6522 22 12.0001M7.91 10.0801L6.5 11.5001L11 16.0001L21 6.00006L19.59 4.58006L11 13.1701L7.91 10.0801Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </span>
                    <span class="" style={{ color: "#353535" }}>
                      Real-Time Updates For Seamless User Experiences.
                    </span>
                  </li>
                </ul>
                <div className="flex justify-center lg:justify-start">
                  {" "}
                  <a href="/api-documentation">
                    {" "}
                    <button
                      class="mt-10  text-white py-3 px-5 rounded-full"
                      style={{ backgroundColor: "#003366" }}
                    >
                      Get Started with Integration
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="hrp-container container-backgroundImage md:mt-0 mt-4 ">
          <AOS animation="slide-left">
            {" "}
            <div className="hrp-Healthcare-Professionals-sub-container">
              <div className="bg-cover container m-auto bg-center relative text-center  px-4 border-1 border-dashed border-gray-300 rounded-md ">
                <div className="lg:pt-24 pb-4 pt-4">
                  {" "}
                  <div className="">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4 hrp-Healthcare-Professionals-heading">
                      Looking for Healthcare Professionals?
                    </h2>
                    <p className="hrp-Healthcare-content" style={{}}>
                      We offer specialised services for healthcare recruiters.
                      Visit our dedicated healthcare hiring page to learn more
                      and connect with top healthcare talent.
                    </p>
                    <div className="hrp-read-now-btn flex justify-center">
                      {" "}
                      <a href="/health-recruiter" className="">
                        <button className="flex text-white m-auto md:px-10 px-5 py-3 rounded-full hover:opacity-90 transition-opacity">
                          {" "}
                          Read More
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 6L15 12L9 18"
                              stroke="white"
                              strokeWidth="2"
                            />
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="md:flex justify-around mt-10 md:space-x-4 items-center hrp-Healthcare-Professionals">
                    <div className="text-center flex mt-2 md:gap-5 items-center">
                      <div className="img-cercle">
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.5 25.5V28.5H10.5V25.5C10.5 25.5 10.5 19.5 19.5 19.5C28.5 19.5 28.5 25.5 28.5 25.5ZM24 12C24 11.11 23.7361 10.24 23.2416 9.49994C22.7471 8.75991 22.0443 8.18314 21.2221 7.84254C20.3998 7.50195 19.495 7.41283 18.6221 7.58647C17.7492 7.7601 16.9474 8.18868 16.318 8.81802C15.6887 9.44736 15.2601 10.2492 15.0865 11.1221C14.9128 11.995 15.0019 12.8998 15.3425 13.7221C15.6831 14.5443 16.2599 15.2471 16.9999 15.7416C17.74 16.2361 18.61 16.5 19.5 16.5C20.6935 16.5 21.8381 16.0259 22.682 15.182C23.5259 14.3381 24 13.1935 24 12ZM28.8 19.59C29.6199 20.3465 30.281 21.2587 30.7446 22.2734C31.2081 23.2881 31.465 24.385 31.5 25.5V28.5H36V25.5C36 25.5 36 20.325 28.8 19.59ZM27 7.5C26.5468 7.50003 26.0963 7.57088 25.665 7.71C26.5426 8.96846 27.0131 10.4658 27.0131 12C27.0131 13.5342 26.5426 15.0315 25.665 16.29C26.0963 16.4291 26.5468 16.5 27 16.5C28.1935 16.5 29.3381 16.0259 30.182 15.182C31.0259 14.3381 31.5 13.1935 31.5 12C31.5 10.8065 31.0259 9.66193 30.182 8.81802C29.3381 7.97411 28.1935 7.5 27 7.5ZM12 15H7.5V10.5H4.5V15H0V18H4.5V22.5H7.5V18H12V15Z"
                            fill="#003366"
                          />
                        </svg>
                      </div>
                      <p className="text-sm ">
                        Our Expertise in Healthcare Hiring
                      </p>
                    </div>
                    <div className="text-center flex mt-2 md:gap-5 items-center">
                      <div className="img-cercle">
                        {" "}
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.5001 9.00014H21.0001L25.9351 4.05014C26.0745 3.90955 26.2404 3.79796 26.4232 3.72181C26.606 3.64565 26.8021 3.60645 27.0001 3.60645C27.1981 3.60645 27.3942 3.64565 27.577 3.72181C27.7597 3.79796 27.9256 3.90955 28.0651 4.05014L31.9351 7.93514C32.2145 8.21619 32.3713 8.59636 32.3713 8.99264C32.3713 9.38892 32.2145 9.7691 31.9351 10.0501L28.5001 13.5001H16.5001V16.5001C16.5001 16.898 16.3421 17.2795 16.0608 17.5608C15.7795 17.8421 15.3979 18.0001 15.0001 18.0001C14.6023 18.0001 14.2207 17.8421 13.9394 17.5608C13.6581 17.2795 13.5001 16.898 13.5001 16.5001V12.0001C13.5001 11.2045 13.8162 10.4414 14.3788 9.87882C14.9414 9.31621 15.7044 9.00014 16.5001 9.00014ZM7.5001 16.5001V22.5001L4.0651 25.9351C3.78572 26.2162 3.62891 26.5964 3.62891 26.9926C3.62891 27.3889 3.78572 27.7691 4.0651 28.0501L7.9351 31.9351C8.07454 32.0757 8.24044 32.1873 8.42323 32.2635C8.60602 32.3396 8.80208 32.3788 9.0001 32.3788C9.19811 32.3788 9.39417 32.3396 9.57696 32.2635C9.75975 32.1873 9.92565 32.0757 10.0651 31.9351L16.5001 25.5001H22.5001C22.8979 25.5001 23.2794 25.3421 23.5608 25.0608C23.8421 24.7795 24.0001 24.398 24.0001 24.0001V22.5001H25.5001C25.8979 22.5001 26.2795 22.3421 26.5608 22.0608C26.8421 21.7795 27.0001 21.398 27.0001 21.0001V19.5001H28.5001C28.8979 19.5001 29.2795 19.3421 29.5608 19.0608C29.8421 18.7795 30.0001 18.398 30.0001 18.0001V16.5001H19.5001V18.0001C19.5001 18.7958 19.184 19.5589 18.6214 20.1215C18.0588 20.6841 17.2957 21.0001 16.5001 21.0001H13.5001C12.7044 21.0001 11.9414 20.6841 11.3788 20.1215C10.8162 19.5589 10.5001 18.7958 10.5001 18.0001V13.5001L7.5001 16.5001Z"
                            fill="#003366"
                          />
                        </svg>
                      </div>

                      <p className="text-sm ">
                        Success Storeys From Our Healthcare Partners
                      </p>
                    </div>
                    <div className="text-center flex mt-2 md:gap-5 items-center">
                      <div className="img-cercle">
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.5 19.5C29.55 19.5 30.555 19.695 31.5 20.025V13.5L22.5 4.5H7.5C5.835 4.5 4.5 5.835 4.5 7.5V28.5C4.5 29.2956 4.81607 30.0587 5.37868 30.6213C5.94129 31.1839 6.70435 31.5 7.5 31.5H20.025C19.695 30.555 19.5 29.55 19.5 28.5C19.5 23.535 23.535 19.5 28.5 19.5ZM21 6.75L29.25 15H21V6.75ZM33.75 25.875L26.625 33L22.5 28.5L24.24 26.76L26.625 29.145L32.01 23.76L33.75 25.875Z"
                            fill="#003366"
                          />
                        </svg>
                      </div>

                      <p className="text-sm ">
                        Get Started – Post Your Healthcare Job Today!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AOS>
        </section>
        <section className="hrp-container  hrp-section-3">
          <AOS animation="slide-right">
            {" "}
            <div className=" hrp-sub-section ">
              <div className="container m-auto mt-20 ">
                <div className="max-w-7xl m-auto">
                  <h1
                    className="lg:text-3xl text-2xl font-bold text-center mb-4"
                    style={{ color: "#3E3E3E" }}
                  >
                    Effortless Hiring Journey: From Posting to Onboarding
                  </h1>
                  <p className="text-gray-600 mb-12 text-center hrp-testimonials  ">
                    Experience a seamless recruitment process with our intuitive
                    platform. From creating job listings to screening
                    candidates, communicating, and finalising offers, our
                    step-by-step approach ensures you attract the best talent,
                    streamline hiring, and effortlessly onboard new team members
                    to accelerate your growth.
                  </p>
                </div>
                <div className="relative vectorcandidate-main employers-page-processes">
                  <div className="Vectorcandidate-image">
                    <img
                      className="m-auto"
                      src={Vectorcandidate}
                      alt="Vectorcandidate"
                    ></img>
                  </div>
                  <div className="lg:flex">
                    <div className="ApplyforJobs-outer firststep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <svg
                                  width="35"
                                  height="35"
                                  viewBox="0 0 35 35"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M16.052 31.3542H14.0074C8.77925 31.3542 6.16591 31.3542 4.54133 29.699C2.91675 28.0438 2.91675 25.3794 2.91675 20.0521C2.91675 14.7248 2.91675 12.0604 4.54133 10.4052C6.16591 8.75 8.77925 8.75 14.0074 8.75H19.5534C24.7815 8.75 27.3963 8.75 29.0209 10.4052C30.2707 11.6783 30.558 13.5494 30.6251 16.7708V18.9583"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                  <path
                                    d="M27.7085 26.9791H23.3335M23.3335 31.3541C22.1732 31.3541 21.0604 30.8931 20.2399 30.0727C19.4194 29.2522 18.9585 28.1394 18.9585 26.9791C18.9585 25.8188 19.4194 24.706 20.2399 23.8855C21.0604 23.065 22.1732 22.6041 23.3335 22.6041M27.7085 31.3541C28.8688 31.3541 29.9816 30.8931 30.8021 30.0727C31.6226 29.2522 32.0835 28.1394 32.0835 26.9791C32.0835 25.8188 31.6226 24.706 30.8021 23.8855C29.9816 23.065 28.8688 22.6041 27.7085 22.6041M23.3335 8.74992L23.1877 8.29784C22.4658 6.052 22.1056 4.92909 21.2466 4.28742C20.3862 3.64575 19.2458 3.64575 16.962 3.64575H16.5785C14.2962 3.64575 13.1543 3.64575 12.2954 4.28742C11.435 4.92909 11.0747 6.052 10.3529 8.29784L10.2085 8.74992"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            {/* <div className="ApplyforJobs-text">Post Job</div> */}
                          </div>
                        </div>
                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Create and publish your job listing to attract
                            qualified candidates.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer secondstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <svg
                                  width="35"
                                  height="35"
                                  viewBox="0 0 35 35"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M27.7083 24.7917V27.7084H10.2083V24.7917C10.2083 24.7917 10.2083 18.9584 18.9583 18.9584C27.7083 18.9584 27.7083 24.7917 27.7083 24.7917ZM23.3333 11.6667C23.3333 10.8015 23.0767 9.9556 22.596 9.23613C22.1153 8.51666 21.432 7.95591 20.6326 7.62478C19.8331 7.29364 18.9535 7.207 18.1048 7.37581C17.2561 7.54462 16.4766 7.9613 15.8647 8.57316C15.2529 9.18501 14.8362 9.96456 14.6674 10.8132C14.4986 11.6619 14.5852 12.5416 14.9164 13.341C15.2475 14.1404 15.8082 14.8237 16.5277 15.3044C17.2472 15.7852 18.093 16.0417 18.9583 16.0417C20.1187 16.0417 21.2315 15.5808 22.0519 14.7603C22.8724 13.9399 23.3333 12.8271 23.3333 11.6667ZM28 19.0459C28.7972 19.7814 29.4399 20.6682 29.8906 21.6547C30.3413 22.6413 30.5909 23.7077 30.625 24.7917V27.7084H35V24.7917C35 24.7917 35 19.7605 28 19.0459ZM26.25 7.29175C25.8094 7.29178 25.3714 7.36066 24.9521 7.49592C25.8053 8.71941 26.2627 10.1751 26.2627 11.6667C26.2627 13.1584 25.8053 14.6141 24.9521 15.8376C25.3714 15.9728 25.8094 16.0417 26.25 16.0417C27.4103 16.0417 28.5231 15.5808 29.3436 14.7603C30.1641 13.9399 30.625 12.8271 30.625 11.6667C30.625 10.5064 30.1641 9.39363 29.3436 8.57316C28.5231 7.75269 27.4103 7.29175 26.25 7.29175ZM11.6667 14.5834H7.29167V10.2084H4.375V14.5834H0V17.5001H4.375V21.8751H7.29167V17.5001H11.6667V14.5834Z"
                                    fill="white"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            {/* <div className="ApplyforJobs-text">
                            Invite Candidates
                          </div> */}
                          </div>
                        </div>
                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Invite suitable candidates to apply for your job
                            openings.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer thirdstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <svg
                                  width="35"
                                  height="35"
                                  viewBox="0 0 35 35"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.77292 4.77163C8.7455 4.42481 9.8004 4.38112 10.7983 4.64634C11.7963 4.91156 12.6903 5.47321 13.3623 6.25713C14.0344 7.04105 14.4529 8.01037 14.5626 9.0371C14.6723 10.0638 14.468 11.0997 13.9767 12.0079L29.594 27.6266L27.5319 29.6887L11.9131 14.07C11.0047 14.5598 9.96916 14.7629 8.94298 14.6526C7.91681 14.5422 6.94816 14.1235 6.16468 13.4516C5.3812 12.7798 4.81967 11.8863 4.55408 10.889C4.28848 9.89167 4.3313 8.83727 4.67687 7.86475L7.93917 11.127C8.35173 11.5255 8.9043 11.746 9.47786 11.741C10.0514 11.736 10.6001 11.506 11.0056 11.1004C11.4112 10.6948 11.6413 10.1462 11.6463 9.57261C11.6512 8.99905 11.4308 8.44649 11.0323 8.03392L7.77292 4.77163ZM22.8915 7.51767L27.5319 4.93933L29.594 7.00288L27.0156 11.6418L24.4373 12.1581L21.3456 15.2512L19.2821 13.1891L22.3752 10.096L22.8915 7.51767ZM13.0944 19.3768L15.1579 21.4389L7.42292 29.1725C7.15998 29.4361 6.80613 29.5893 6.43392 29.6004C6.06171 29.6115 5.69933 29.4799 5.42108 29.2324C5.14284 28.9849 4.9698 28.6404 4.93745 28.2694C4.90509 27.8985 5.01588 27.5292 5.24708 27.2373L5.36083 27.1104L13.0944 19.3768Z"
                                    fill="white"
                                  ></path>
                                </svg>
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            {/* <div className="ApplyforJobs-text">
                            Screening Tools
                          </div> */}
                          </div>
                        </div>
                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Utilise advanced screening tools to filter and
                            shortlist the best candidates.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer fourthstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Stepfour}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            {/* <div className="ApplyforJobs-text">
                            Easy Communication
                          </div> */}
                          </div>
                        </div>
                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Communicate effortlessly with applicants through our
                            integrated messaging system.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="ApplyforJobs-outer fifthstep">
                      <div>
                        <div className="ApplyforJobs-section">
                          <div>
                            <div className="ApplyforJobs">
                              <div>
                                <img
                                  src={Stepfive}
                                  alt="Profile Image Overlay"
                                />
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <div className="JobDiscussions">
                                <div className="JobDiscussions-inner"></div>
                              </div>
                            </div>
                            {/* <div className="ApplyforJobs-text">Hire</div> */}
                          </div>
                        </div>
                        <div className="ApplyforJobs-contents">
                          <p className="text-center">
                            Make offers and onboard new hires seamlessly.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </AOS>
        </section>
        <section className="hrp-container hrp-section-4">
          <div className=" hrp-sub-section hrp-Employees-outer ">
            <div className="  py-16">
              <div className="max-w-7xl mx-auto text-center hrp-Employees-outer">
                <h1
                  className="lg:text-3xl font-bold mb-4"
                  style={{ color: "#3E3E3E" }}
                >
                  Testimonials From Our Partners
                </h1>
                <p className="text-gray-600 mb-12 hrp-testimonials  ">
                  "At HirePros, our mission is to simplify and enhance the
                  recruitment process. Here's what our partners have to say
                  about their experience with our platform:"
                </p>

                <div className="container m-auto">
                  <Carousel showThumbs={false} {...settings}>
                    {testimonials.map((testimonial) => (
                      <div className=" hrp-testimonials-main">
                        <div
                          key={testimonial.id}
                          className="relative bg-white shadow-md justify-center rounded-lg md:p-8 lg:p-8 p-4 md:items-center mx-4"
                        >
                          <div className="testimonials-section-first">
                            <div className="relative  rounded-full border-4  border-white z-10 mx-auto md:mx-0 w-28 lg:-mt-16  ">
                              <img
                                src={testimonial.image}
                                alt={testimonial.name}
                                className="w-full h-full object-cover rounded-full"
                              />
                            </div>
                          </div>
                          <div className=" md:mt-0  testimonials-section-second">
                            <div className=" hrp-testimonials-inner-section-1">
                              <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18 22.5H9.165C9.44378 20.6435 10.1067 18.8656 11.1114 17.2797C12.1161 15.6939 13.4405 14.3351 15 13.29L17.685 11.49L16.035 9L13.35 10.8C11.0899 12.3061 9.23656 14.3468 7.9544 16.7411C6.67223 19.1353 6.00091 21.8091 6 24.525V34.5C6 35.2956 6.31607 36.0587 6.87868 36.6213C7.44129 37.1839 8.20435 37.5 9 37.5H18C18.7956 37.5 19.5587 37.1839 20.1213 36.6213C20.6839 36.0587 21 35.2956 21 34.5V25.5C21 24.7044 20.6839 23.9413 20.1213 23.3787C19.5587 22.8161 18.7956 22.5 18 22.5ZM39 22.5H30.165C30.4438 20.6435 31.1067 18.8656 32.1114 17.2797C33.1161 15.6939 34.4405 14.3351 36 13.29L38.685 11.49L37.05 9L34.35 10.8C32.0899 12.3061 30.2366 14.3468 28.9544 16.7411C27.6722 19.1353 27.0009 21.8091 27 24.525V34.5C27 35.2956 27.3161 36.0587 27.8787 36.6213C28.4413 37.1839 29.2044 37.5 30 37.5H39C39.7957 37.5 40.5587 37.1839 41.1213 36.6213C41.6839 36.0587 42 35.2956 42 34.5V25.5C42 24.7044 41.6839 23.9413 41.1213 23.3787C40.5587 22.8161 39.7957 22.5 39 22.5Z"
                                  fill="#FFA500"
                                />
                              </svg>
                            </div>
                            <div className="hrp-testimonials-inner-middle">
                              <div className="p-6">
                                <h4 className=" ">{testimonial.name}</h4>
                                <p className="">{testimonial.position}</p>
                              </div>
                              <blockquote className="text-gray-600 mb-4 lg:p-6 md:p-0">
                                {testimonial.quote}
                              </blockquote>
                            </div>
                            <div className="hrp-testimonials-inner-section-2">
                              <svg
                                width="48"
                                height="48"
                                viewBox="0 0 48 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M30 25.5H38.835C38.5562 27.3565 37.8933 29.1344 36.8886 30.7203C35.8839 32.3061 34.5595 33.6649 33 34.71L30.315 36.51L31.965 39L34.65 37.2C36.9101 35.6939 38.7634 33.6532 40.0456 31.2589C41.3278 28.8647 41.9991 26.1909 42 23.475V13.5C42 12.7044 41.6839 11.9413 41.1213 11.3787C40.5587 10.8161 39.7956 10.5 39 10.5H30C29.2044 10.5 28.4413 10.8161 27.8787 11.3787C27.3161 11.9413 27 12.7044 27 13.5V22.5C27 23.2956 27.3161 24.0587 27.8787 24.6213C28.4413 25.1839 29.2044 25.5 30 25.5ZM9 25.5H17.835C17.5562 27.3565 16.8933 29.1344 15.8886 30.7203C14.8839 32.3061 13.5595 33.6649 12 34.71L9.315 36.51L10.95 39L13.65 37.2C15.9101 35.6939 17.7634 33.6532 19.0456 31.2589C20.3278 28.8647 20.9991 26.1909 21 23.475V13.5C21 12.7044 20.6839 11.9413 20.1213 11.3787C19.5587 10.8161 18.7956 10.5 18 10.5H9C8.20435 10.5 7.44129 10.8161 6.87868 11.3787C6.31607 11.9413 6 12.7044 6 13.5V22.5C6 23.2956 6.31607 24.0587 6.87868 24.6213C7.44129 25.1839 8.20435 25.5 9 25.5Z"
                                  fill="#FFA500"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <AOS animation="fade-right">
            <div className="bg-gray-100 py-16 suceessection">
              <div className="max-w-7xl mx-auto text-center">
                <h2
                  className="text-3xl font-bold mb-4"
                  style={{
                    color: "#3E3E3E",
                  }}
                >
                  Our Success & Award
                </h2>
                <p
                  className="text-gray-600 mb-12"
                  style={{
                    width: "70%",
                    margin: "0 auto",
                    padding: "0 0 20px 0",
                  }}
                >
                  At HirePros, we’re proud of our industry accolades and
                  achievements. Our commitment to innovation and excellence has
                  earned us recognition, showcasing our dedication to providing
                  exceptional recruitment solutions and exceptional client
                  value.
                </p>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-8 md:m-10 mx-3">
                  <div className="bg-white hrp-departments-main-card shadow-md hover:shadow-xl transition-all border-b-4 border-yellow-300  p-4">
                    <div className="flex justify-center xl:pt-10 lg:pt-5">
                      <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.66659 45.5002C7.47492 45.5002 6.45514 45.0762 5.60725 44.2283C4.75936 43.3804 4.3347 42.3599 4.33325 41.1668V32.5002H19.4999V36.8335H32.4999V32.5002H47.6666V41.1668C47.6666 42.3585 47.2426 43.379 46.3948 44.2283C45.5469 45.0777 44.5264 45.5016 43.3333 45.5002H8.66659ZM23.8333 32.5002V28.1668H28.1666V32.5002H23.8333ZM4.33325 28.1668V17.3335C4.33325 16.1418 4.75792 15.1221 5.60725 14.2742C6.45659 13.4263 7.47636 13.0016 8.66659 13.0002H17.3333V8.66683C17.3333 7.47516 17.7579 6.45539 18.6073 5.6075C19.4566 4.75961 20.4764 4.33494 21.6666 4.3335H30.3333C31.5249 4.3335 32.5454 4.75816 33.3948 5.6075C34.2441 6.45683 34.668 7.47661 34.6666 8.66683V13.0002H43.3333C44.5249 13.0002 45.5454 13.4248 46.3948 14.2742C47.2441 15.1235 47.668 16.1433 47.6666 17.3335V28.1668H32.4999V23.8335H19.4999V28.1668H4.33325ZM21.6666 13.0002H30.3333V8.66683H21.6666V13.0002Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </div>{" "}
                    <h3
                      className="text-3xl font-medium mb-2 p-4 countanimation"
                      style={{
                        color: " #FFA500",
                      }}
                    >
                      <CountUp target={data.totalJobs} />
                    </h3>
                    <p
                      className="text-center font-medium text-gray"
                      style={{ color: "#7b7979" }}
                    >
                      Live Jobs
                    </p>
                  </div>
                  <div className="bg-white hrp-departments-main-card shadow-md hover:shadow-xl transition-all border-b-4 border-yellow-300  p-4">
                    <div className="flex justify-center xl:pt-10 lg:pt-5">
                      <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M45.0102 38.9998C46.6352 38.9998 47.9244 37.9793 49.0835 36.5515C51.4582 33.6265 47.5604 31.2865 46.074 30.1447C44.5639 28.9812 42.876 28.3203 41.1665 28.1665M38.9999 23.8332C40.4364 23.8332 41.8142 23.2625 42.83 22.2467C43.8458 21.2308 44.4165 19.8531 44.4165 18.4165C44.4165 16.9799 43.8458 15.6022 42.83 14.5863C41.8142 13.5705 40.4364 12.9998 38.9999 12.9998M6.98952 38.9998C5.36452 38.9998 4.07535 37.9793 2.91618 36.5515C0.541518 33.6265 4.43935 31.2865 5.92568 30.1447C7.43585 28.9812 9.12152 28.3182 10.8332 28.1665M11.9165 23.8332C10.4799 23.8332 9.10218 23.2625 8.08636 22.2467C7.07053 21.2308 6.49985 19.8531 6.49985 18.4165C6.49985 16.9799 7.07053 15.6022 8.08636 14.5863C9.10218 13.5705 10.4799 12.9998 11.9165 12.9998M17.5152 32.7403C15.3009 34.1097 9.49635 36.9047 13.0302 40.4017C14.7614 42.1112 16.6832 43.3332 19.1034 43.3332H32.9007C35.3209 43.3332 37.2427 42.1112 38.9695 40.4017C42.5055 36.9047 36.701 34.1097 34.4867 32.7403C31.9362 31.1673 28.9986 30.3343 26.002 30.3343C23.0054 30.3343 20.0679 31.1673 17.5174 32.7403M33.5832 16.2498C33.5832 17.2457 33.387 18.2318 33.0059 19.1519C32.6248 20.0719 32.0663 20.9079 31.3621 21.6121C30.6579 22.3162 29.8219 22.8748 28.9019 23.2559C27.9818 23.637 26.9957 23.8332 25.9999 23.8332C25.004 23.8332 24.0179 23.637 23.0978 23.2559C22.1778 22.8748 21.3418 22.3162 20.6376 21.6121C19.9334 20.9079 19.3749 20.0719 18.9938 19.1519C18.6127 18.2318 18.4165 17.2457 18.4165 16.2498C18.4165 14.2386 19.2155 12.3098 20.6376 10.8876C22.0598 9.46546 23.9886 8.6665 25.9999 8.6665C28.0111 8.6665 29.9399 9.46546 31.3621 10.8876C32.7842 12.3098 33.5832 14.2386 33.5832 16.2498Z"
                          stroke="#FFA500"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>{" "}
                    <h3
                      className="text-3xl font-medium mb-2 p-4"
                      style={{
                        color: " #FFA500",
                      }}
                    >
                      <CountUp target={data.totalApplicants} />
                    </h3>
                    <p
                      className="text-center font-medium"
                      style={{ color: "#7b7979" }}
                    >
                      Jobs Candidate
                    </p>
                  </div>
                  <div className="bg-white hrp-departments-main-card shadow-md hover:shadow-xl transition-all border-b-4 border-yellow-300  p-4">
                    <div className="flex justify-center xl:pt-10 lg:pt-5">
                      <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.1665 19.5002H40.0832L28.1665 7.5835V19.5002ZM12.9998 4.3335H30.3332L43.3332 17.3335V43.3335C43.3332 44.4828 42.8766 45.585 42.064 46.3976C41.2513 47.2103 40.1491 47.6668 38.9998 47.6668H12.9998C11.8506 47.6668 10.7484 47.2103 9.93571 46.3976C9.12305 45.585 8.6665 44.4828 8.6665 43.3335V8.66683C8.6665 6.26183 10.5948 4.3335 12.9998 4.3335ZM30.3332 43.3335V41.1668C30.3332 38.2852 24.5482 36.8335 21.6665 36.8335C18.7848 36.8335 12.9998 38.2852 12.9998 41.1668V43.3335H30.3332ZM21.6665 26.0002C20.5172 26.0002 19.415 26.4567 18.6024 27.2694C17.7897 28.082 17.3332 29.1842 17.3332 30.3335C17.3332 31.4828 17.7897 32.585 18.6024 33.3976C19.415 34.2103 20.5172 34.6668 21.6665 34.6668C22.8158 34.6668 23.918 34.2103 24.7306 33.3976C25.5433 32.585 25.9998 31.4828 25.9998 30.3335C25.9998 29.1842 25.5433 28.082 24.7306 27.2694C23.918 26.4567 22.8158 26.0002 21.6665 26.0002Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </div>{" "}
                    <h3
                      className="text-3xl font-medium mb-2 p-4"
                      style={{
                        color: " #FFA500",
                      }}
                    >
                      <CountUp target={data.totalActiveResumes || 0} />
                    </h3>
                    <p
                      className="text-center font-medium"
                      style={{ color: "#7b7979" }}
                    >
                      Active Resume
                    </p>
                  </div>

                  <div className="bg-white hrp-departments-main-card shadow-md hover:shadow-xl transition-all border-b-4 border-yellow-300  p-4">
                    <div className="flex justify-center xl:pt-10 lg:pt-5">
                      <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M45.5 48.75V13H35.75V3.25H6.5V48.75H0V52H22.75V42.25H29.25V52H52V48.75H45.5ZM19.5 35.75H13V29.25H19.5V35.75ZM19.5 26H13V19.5H19.5V26ZM19.5 16.25H13V9.75H19.5V16.25ZM29.25 35.75H22.75V29.25H29.25V35.75ZM29.25 26H22.75V19.5H29.25V26ZM29.25 16.25H22.75V9.75H29.25V16.25ZM42.25 35.75H35.75V29.25H42.25V35.75ZM42.25 26H35.75V19.5H42.25V26Z"
                          fill="#FFA500"
                        />
                      </svg>
                    </div>{" "}
                    <h3
                      className="text-3xl font-medium mb-2 p-4"
                      style={{
                        color: " #FFA500",
                      }}
                    >
                      <CountUp target={data.totalCompanies} />
                    </h3>
                    <p
                      className="text-center font-medium"
                      style={{ color: "#7b7979" }}
                    >
                      Companies
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </AOS>
        </section>
        <sction className="hrp-container hrp-section-5">
          <div className=" hrp-sub-section ">
            <div className="  py-16 contactus">
              <div className="max-w-4xl mx-auto text-center">
                <h1
                  className="lg:text-3xl font-bold mb-4"
                  style={{ color: "#3E3E3E" }}
                >
                  Contact Us
                </h1>
                <p className="text-gray-600 mb-12">
                  We’re here to help! Whether you have questions, feedback, or
                  need assistance, please reach out to us. Our team is dedicated
                  to providing prompt and effective support to ensure your
                  experience is seamless and satisfying.
                </p>
                <form
                  onSubmit={handleSubmit}
                  id="contactus"
                  className="space-y-6 animated-form"
                  style={{
                    width: "75%",
                    margin: "0 auto",
                  }}
                >
                  <div className="form-group">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="name" className="form-input-label">
                      Name
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      id="company"
                      type="text"
                      name="company"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="company" className="form-input-label">
                      Company
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="email" className="form-input-label">
                      Email
                    </label>
                  </div>
                  <div className="form-group">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder=""
                      className="w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:outline-none focus:border-[#FFA500]"
                    />
                    <label htmlFor="phone" className="form-input-label">
                      Phone Number
                    </label>
                  </div>
                  <div className="form-group relative">
                    <textarea
                      id="query"
                      name="query"
                      placeholder=""
                      className="w-full px-4 py-2 focus:outline-none focus:border-[#FFA500] h-32 peer"
                    />
                    <label
                      htmlFor="query"
                      className="form-input-label absolute top-2 left-4 transition-all duration-300 peer-placeholder-shown:top-1/2 peer-placeholder-shown:left-4 peer-focus:-top-2 peer-focus:left-4 peer-focus:text-[#FFA500] text-gray-600"
                    >
                      Query
                    </label>
                  </div>

                  <div style={{ textAlign: "left" }}>
                    <button
                      type="submit"
                      className=" py-3 px-10 btnpostjob text-xl rounded-lg "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </sction>
      </div>{" "}
      <VisitorTracker />
    </>
  );
};
export default Employers;
