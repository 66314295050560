// AdminLayout.js
import React, { useState, useEffect } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import Footer from "./Footer";
import "../../components/Layout/menubaar.css";
import { CandidateHeader } from "./Header";
import Dasboard from "../Candidates/Dasboard";
import { Candidates } from "./dashboardmenus";
import { PopupProvider } from "../Common/PopupContext";
import Profile from "../Candidates/Profile";
import CandidateProfile from "../Candidates/Profile-single.js";
import CreateProfile from "../Candidates/CreateProfile.js";
import Jobs from "../../components/Website/jobs.js";
import AccountSettings from "../Candidates/Sitesettings";
import Jobsaved from "../Candidates/SavedJob";
import Jobapplied from "../Candidates/Jobapplied.js";
import JobApply from "../Candidates/JobApply.js";
import Jobalerts from "../Candidates/Jobalerts.js";
import Jobinterviewed from "../Candidates/Jobinterviewed.js";
import ScheduleInterView from "../Candidates/ScheduleInterView.js";
import Jobsapplying from "../Candidates/Jobsapplying.js";
import DeleteAccount from "../Candidates/DeleteAccount";
import UserProfile from "../Candidates/UserProfile";
import Messages from "../Candidates/Messages";
import { CVDownloadProvider } from "../../helpers/CVDownloadContext";
function ApplicantLayout() {
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split("; ");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null; // Return null if the cookie is not found
  };
  const useLocalStorage = (key) => {
    // Retrieve from localStorage
    const [storedValue, setStoredValue] = useState(() => {
      const item = localStorage.getItem(key);
      if (item) return item;

      // If not found in localStorage, try to retrieve it from cookies
      const cookieValue = getCookie(key);
      if (cookieValue) {
        // Set to localStorage if found in cookies
        localStorage.setItem(key, cookieValue);
        return cookieValue;
      }

      return null;
    });

    return storedValue;
  };
  const user = useLocalStorage("accessToken");
  useEffect(() => {}, [user]);

  let { path } = useRouteMatch();

  return (
    <CVDownloadProvider>
      <PopupProvider>
        <div className="">
          <CandidateHeader />

          <div className=" hrp-custom-design-style mx-5 m-auto hrp-min-h-screen">
            <div className="w-full  hrp-min-width">
              {user ? <Candidates /> : ""}
              <section className="mt-5">
                <div className="bg-white container  hrp-candidate-page JobCategories-heading-admin m-auto 	">
                  <Switch>
                    <Route exact path={path} component={Dasboard} />
                    <Route exact path={`${path}/profile`} component={Profile} />
                    <Route
                      exact
                      path={`${path}/userprofile/:id`}
                      component={UserProfile}
                    />
                    <Route
                      path={`${path}/profile/:profileid`}
                      component={CandidateProfile}
                    />
                    <Route
                      path={`${path}/applyjob/:jobid/:profileid`}
                      component={JobApply}
                    />
                    <Route
                      path={`${path}/create-profile`}
                      component={CreateProfile}
                    />
                    <Route path={`${path}/messages`} component={Messages} />
                    <Route path={`${path}/job-saved`} component={Jobsaved} />
                    <Route
                      path={`${path}/jobs-applying`}
                      component={Jobsapplying}
                    />
                    <Route
                      path={`${path}/job-applied`}
                      component={Jobapplied}
                    />
                    <Route
                      path={`${path}/job-interviewed`}
                      component={Jobinterviewed}
                    />
                    <Route
                      path={`${path}/scheduled-interviewed`}
                      component={ScheduleInterView}
                    />

                    <Route path={`${path}/job-alerts`} component={Jobalerts} />
                    <Route path={`/jobs`} component={Jobs} />
                    <Route
                      path={`${path}/site-settings`}
                      component={AccountSettings}
                    />
                    <Route
                      path={`${path}/delete-account`}
                      component={DeleteAccount}
                    />
                    {/* Add other routes as needed */}
                  </Switch>
                </div>
              </section>
            </div>
          </div>
          <Footer />
        </div>
      </PopupProvider>
    </CVDownloadProvider>
  );
}

export default ApplicantLayout;
