import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

const Sidebar = ({ setActiveSection, activeSection }) => {
  const [isJobsMenuOpen, setIsJobsMenuOpen] = useState(true);
  return (
    <div className="sidebar bg-gray-800 text-white w-1/4 p-4 h-screen sticky top-0">
      <h2 className="text-lg font-bold mb-4">API Instructions</h2>
      <p
        className={`block w-full text-left py-2  cursor-pointer ${
          activeSection === "authorisation" ? "activestep" : ""
        }`}
        onClick={() => setActiveSection("authorisation")}
      >
        Authorisation
      </p>

      <p
        className={`block w-full text-left py-2  cursor-pointer ${
          activeSection === "validatetoken" ? "activestep" : ""
        }`}
        onClick={() => setActiveSection("validatetoken")}
      >
        Validate Token
      </p>

      {/* Jobs Menu Section */}
      <div className="">
        <p
          className={` py-2 cursor-pointer flex items-center ${
            isJobsMenuOpen ? "jobsp" : ""
          }`}
          onClick={() => setIsJobsMenuOpen(!isJobsMenuOpen)} // Toggle submenu visibility
        >
          Jobs
          <span
            className={`ml-2 transform transition-transform duration-300 ${
              isJobsMenuOpen ? "rotate-90" : ""
            }`}
          >
            ▶
          </span>
        </p>
        {isJobsMenuOpen && (
          <ul className="pl-4">
            <li
              className={`py-2 cursor-pointer ${
                activeSection === "jobs-all" ? "activestep" : ""
              }`}
              onClick={() => setActiveSection("jobs-all")}
            >
              All Jobs
            </li>
            <li
              className={`py-2 cursor-pointer ${
                activeSection === "jobs-single" ? "activestep" : ""
              }`}
              onClick={() => setActiveSection("jobs-single")}
            >
              Single Job
            </li>
            <li
              className={`py-2 cursor-pointer ${
                activeSection === "jobs-search" ? "activestep" : ""
              }`}
              onClick={() => setActiveSection("jobs-search")}
            >
              Search Jobs
            </li>
          </ul>
        )}
      </div>
      <hr className="my-4" style={{ border: "1px solid #003366" }} />
      <h2 className="text-lg font-bold mt-4">Integrate</h2>
      <p
        className={`block w-full text-left py-2  cursor-pointer ${
          activeSection === "wordpress" ? "activestep " : ""
        }`}
        onClick={() => setActiveSection("wordpress")}
      >
        WordPress Plugin
      </p>
      <p
        className={`block w-full text-left py-2  cursor-pointer ${
          activeSection === "embed-code" ? "activestep " : ""
        }`}
        onClick={() => setActiveSection("embed-code")}
      >
        Embed Code
      </p>
    </div>
  );
};
export default Sidebar;
