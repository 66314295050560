import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import FeatherIcon from "feather-icons-react";
import CustomDataTable from "../Common/Customsdatatable";
import { usePopup } from "../Common/PopupContext";
import CustomToastContainer from "../Common/CustomToastContainer";
import AddDomain from "../popup/AddDomian";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ManageDomains = () => {
  const { openPopup } = usePopup();
  const [domains, setDomains] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const toastRef = useRef();
  const history = useHistory();
  useEffect(() => {
    fetchDomains(page, perPage);
  }, [page, perPage]);

  const fetchDomains = async (page, limit) => {
    try {
      const response = await api.getDomains(page, limit);
      console.log(response);

      if (response) {
        setDomains(response.domains || []);
        setTotalRows(response.total || 0);
      } else {
        setDomains([]);
        setTotalRows(0);
      }
    } catch (error) {
      console.error("Error fetching domains:", error);
      setDomains([]);
      setTotalRows(0);
    }
  };

  const openDomainPopup = (domain = null) => {
    const popup = (
      <AddDomain
        onClose={() => openPopup()}
        domain={domain}
        refreshData={() => fetchDomains(page, perPage)}
      />
    );
    openPopup(popup);
  };

  const handleDelete = async (id) => {
    try {
      await api.deleteDomain(id);
      toastRef.current.addToast("Domain Deleted Successfully", "success");
      fetchDomains(page, perPage);
    } catch (error) {
      console.error("Error deleting domain:", error);
      toastRef.current.addToast("Error deleting domain", "error");
    }
  };
  const columns = [
    {
      name: "Domain Name",
      selector: (row) => (
        <a href={`/dashboard/visitor-by-domain?domain=${row.url}`}>
          {row.name}
        </a>
      ),
    },
    { name: "URL", selector: (row) => row.url },
    {
      name: "Actions",
      selector: (row) => (
        <div className="relative">
          <button className="mr-2" onClick={() => openDomainPopup(row)}>
            <FeatherIcon icon="edit-2" />
          </button>
          <button onClick={() => handleDelete(row._id)}>
            <FeatherIcon icon="trash-2" className="text-red-500" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <button
        className="hrp-find-course-btn py-2 px-6 rounded-full my-2"
        onClick={() => openDomainPopup()}
      >
        Add Domain
      </button>
      <CustomDataTable
        columns={columns}
        data={domains}
        totalRows={totalRows}
        onPageChange={setPage}
        onRowsPerPageChange={setPerPage}
        currentPage={page}
      />
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageDomains;
