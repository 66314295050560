import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CustomToastContainer from "../Common/CustomToastContainer";
import api from "../../Api";
import FeatherIcon from "feather-icons-react";

const ManageVisitors = () => {
  const toastRef = useRef();
  const [render, setRender] = useState(0);
  const [data, setData] = useState([]);
  const [selectedVisitors, setSelectedVisitors] = useState([]); // For storing selected visitors
  const history = useHistory();
  // Fetch visitor data from the API
  useEffect(() => {
    async function getVisitors() {
      try {
        const response = await api.getVisitorData();

        // Adjust this to match your actual API method
        if (response.success === true) {
          setData(response.visitors); // Adjust based on actual API response
        }
      } catch (error) {
        console.error("Error fetching visitors", error);
      }
    }
    getVisitors();
  }, [render]);

  // Function to handle delete visitor action
  const handleDelete = async (id) => {
    try {
      const response = await api.deleteVisitor(id); // Ensure this API route exists
      if (response.status === 200) {
        toastRef.current.addToast("Visitor deleted successfully", "success");
        setRender((prev) => prev + 1); // Trigger re-render to refresh data
      } else {
        toastRef.current.addToast("Failed to delete visitor", "error");
      }
    } catch (error) {
      console.error("Error deleting visitor:", error);
      toastRef.current.addToast("An error occurred while deleting", "error");
    }
  };

  // Handle bulk delete
  const handleBulkDelete = async () => {
    try {
      if (selectedVisitors.length > 0) {
        var data = {
          ids: selectedVisitors,
        };
        const response = await api.deleteVisitors(data); // Make sure this calls the correct API
        if (response.status === 200) {
          toastRef.current.addToast(
            "Selected visitors deleted successfully",
            "success"
          );
          setRender((prev) => prev + 1);
          setSelectedVisitors([]); // Clear selection after deletion
        } else {
          toastRef.current.addToast("Failed to delete visitors", "error");
        }
      } else {
        toastRef.current.addToast("No visitors selected", "warning");
      }
    } catch (error) {
      console.error("Error deleting visitors:", error);
      toastRef.current.addToast("An error occurred while deleting", "error");
    }
  };

  // Select all rows
  const handleRowSelect = (row) => {
    const id = row._id; // Adjust if needed
    //console.log("Current selected visitors:", selectedVisitors);
    //console.log("Toggling ID:", id);

    setSelectedVisitors((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((visitorId) => visitorId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      const allIds = data.map((visitor) => visitor._id); // Adjust here
      setSelectedVisitors(allIds);
    } else {
      setSelectedVisitors([]);
    }
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          onChange={(e) => handleSelectAll(e.target.checked)}
          checked={
            selectedVisitors.length > 0 &&
            selectedVisitors.length === data.length
          }
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={selectedVisitors.includes(row._id)}
          onChange={() => handleRowSelect(row)}
        />
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: "IP Address",
      selector: (row) => (
        <p
          className="text-blue-500 underline cursor-pointer"
          onClick={() => history.push(`/dashboard/visitors-history/${row.ip}`)}
        >
          {row.ip}
        </p>
      ),
      sortable: true,
    },
    {
      name: "Referer",
      selector: (row) => row.referer,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.date).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Time Spent",
      selector: (row) => `${row.timeSpent} sec`,
      sortable: true,
    },
    {
      name: "URL",
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: "Site",
      selector: (row) => row.site,
      sortable: true,
    },
    {
      name: "Page URL",
      selector: (row) => row.pageUrl,
      sortable: true,
    },
    {
      name: "Clicks",
      selector: (row) => row.clicks,
      sortable: true,
    },
    {
      name: "Latitude",
      selector: (row) => row.lat,
      sortable: true,
    },
    {
      name: "Longitude",
      selector: (row) => row.long,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <button
          className="manage-delete-btn text-white px-6 py-2"
          onClick={() => handleDelete(row._id)}
        >
          <FeatherIcon icon="trash" />
        </button>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  return (
    <div className="py-8">
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold" style={{ color: "#003366" }}>
            Visitors
          </h2>
          {selectedVisitors?.length > 0 ? (
            <button
              className="manage-delete-btn text-white px-6 py-2"
              onClick={handleBulkDelete}
              disabled={selectedVisitors.length === 0}
            >
              Delete Selected
            </button>
          ) : (
            ""
          )}
        </div>
        <DataTableExtensions {...tableData}>
          <DataTable
            columns={columns}
            data={data}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            highlightOnHover
          />
        </DataTableExtensions>
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default ManageVisitors;
