import React, { useEffect, useState } from "react";
import axios from "axios";
import ChartComponent from "../Common/Chartsection";
import CustomDataTable from "../Common/Customsdatatable";
import { useParams, useHistory } from "react-router-dom";
const JobViews = () => {
  const { jobid } = useParams();
  const [jobViewsData, setJobViewsData] = useState({
    allViews: [],
    totals: [],
  });
  const history = useHistory();
  const clientdomainpath = process.env.REACT_APP_DOMAIN;
  const logoimage = clientdomainpath + "/staticlogo.png";
  const [page, setPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [metrics, setMetrics] = useState({});
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [period, setPeriod] = useState("daily");
  const [filters, setFilters] = useState({
    city: "",
    device: "",
    startDate: "",
    endDate: "",
  });
  const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

  const fetchJobViews = async () => {
    try {
      const response = await axios.get(
        `${domainpath}/api/job/job-views/${jobid}`,
        {
          params: { page, limit: perPage, period },
        }
      );
      const {
        allViews,
        totals,
        totalRows,
        uniqueVisitors,
        averageTimeSpent,
        totalClicks,
      } = response.data;
      setData(response.data.jobDetails);
      setJobViewsData({ allViews, totals });
      setMetrics({ uniqueVisitors, averageTimeSpent, totalClicks });
      setTotalRows(totalRows);
    } catch (error) {
      console.error("Error fetching job views:", error);
    }
  };

  useEffect(() => {
    fetchJobViews();
  }, [page, perPage, period, filters]);

  const handlePageChange = (newPage) => setPage(newPage);
  const handlePerRowsChange = (newRowsPerPage) => setPerPage(newRowsPerPage);
  console.log(data, "data");

  const columns = [
    {
      name: "",
      selector: (row) => (
        <>
          <img
            src={
              data.company.logo.startsWith("http")
                ? data.company.logo
                : data.company.logo.includes("uploads")
                ? `${domainpath}${data.company.logo}`
                : `${domainpath}/uploads/${data.company.logo}`
            }
            alt={`${data.company.name} Logo`}
            onClick={() => history.push(`/companies/${data.company.name}`)}
            style={{ width: "120px" }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = logoimage; // Replace with a default logo
            }}
          />
        </>
      ),
      width: "10%",
      sortable: true,
    },
    {
      name: "Job Title",
      selector: (row) => (
        <>
          <a
            href={`${domainpath}/jobs/${data?._id}?job=${data?.title.replace(
              /\s+/g,
              "-"
            )}&company=${data?.company?.name.replace(/\s+/g, "-")}`}
            className="hover:underline"
            style={{ color: "#003366", fontSize: "18px", fontWeight: 600 }}
          >
            {data?.title || ""}
          </a>

          <div style={{ color: "#003366", fontSize: "13px" }}>
            {" "}
            {data?.company ? `${data?.company?.name}` : ""}
          </div>
          <div style={{ fontSize: "13px" }}>
            {data?.location?.address ||
              `${data?.location?.city} ${data?.location?.area} ${data?.location?.pincode}`}
          </div>
        </>
      ),
      width: "25%",
      sortable: true,
    },
    { name: "Clicks", selector: (row) => row.clicks, sortable: true },
    {
      name: "Date",
      selector: (row) => formatDate(row),
      sortable: true,
    },

    {
      name: "Time Spent (sec)",
      selector: (row) => row.timeSpent,
      sortable: true,
    },
  ];
  const formatDate = (row) => {
    const today = new Date();
    const rowDate = new Date(row.date);

    // Check if the date is today
    if (
      rowDate.getDate() === today.getDate() &&
      rowDate.getMonth() === today.getMonth() &&
      rowDate.getFullYear() === today.getFullYear()
    ) {
      return "Today";
    }

    // Otherwise, format the date
    return rowDate.toLocaleString();
  };

  return (
    <div>
      {/* Period Tabs */}{" "}
      <div className="md:flex mb-10">
        <div className="md:w-3/5">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
            {/* Unique Visitors */}
            <div className="dashboardsection-card flex gap-3 items-center bg-blue-100 p-4 rounded-lg shadow-md">
              <div className="dashboardsection-image ">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#fff"
                    d="M320 64A64 64 0 1 0 192 64a64 64 0 1 0 128 0zm-96 96c-35.3 0-64 28.7-64 64l0 48c0 17.7 14.3 32 32 32l1.8 0 11.1 99.5c1.8 16.2 15.5 28.5 31.8 28.5l38.7 0c16.3 0 30-12.3 31.8-28.5L318.2 304l1.8 0c17.7 0 32-14.3 32-32l0-48c0-35.3-28.7-64-64-64l-64 0zM132.3 394.2c13-2.4 21.7-14.9 19.3-27.9s-14.9-21.7-27.9-19.3c-32.4 5.9-60.9 14.2-82 24.8c-10.5 5.3-20.3 11.7-27.8 19.6C6.4 399.5 0 410.5 0 424c0 21.4 15.5 36.1 29.1 45c14.7 9.6 34.3 17.3 56.4 23.4C130.2 504.7 190.4 512 256 512s125.8-7.3 170.4-19.6c22.1-6.1 41.8-13.8 56.4-23.4c13.7-8.9 29.1-23.6 29.1-45c0-13.5-6.4-24.5-14-32.6c-7.5-7.9-17.3-14.3-27.8-19.6c-21-10.6-49.5-18.9-82-24.8c-13-2.4-25.5 6.3-27.9 19.3s6.3 25.5 19.3 27.9c30.2 5.5 53.7 12.8 69 20.5c3.2 1.6 5.8 3.1 7.9 4.5c3.6 2.4 3.6 7.2 0 9.6c-8.8 5.7-23.1 11.8-43 17.3C374.3 457 318.5 464 256 464s-118.3-7-157.7-17.9c-19.9-5.5-34.2-11.6-43-17.3c-3.6-2.4-3.6-7.2 0-9.6c2.1-1.4 4.8-2.9 7.9-4.5c15.3-7.7 38.8-14.9 69-20.5z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-lg font-bold" style={{ color: "#003366" }}>
                  {metrics.uniqueVisitors}
                </p>
                <h3 className="text-gray-500" style={{ color: "#ffa500" }}>
                  Unique Visitors
                </h3>
              </div>
            </div>

            {/* Average Time */}
            <div className="dashboardsection-card  flex gap-3 items-center bg-green-100 p-4 rounded-lg shadow-md">
              <div className="dashboardsection-image ">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="#fff"
                    d="M75 75L41 41C25.9 25.9 0 36.6 0 57.9L0 168c0 13.3 10.7 24 24 24l110.1 0c21.4 0 32.1-25.9 17-41l-30.8-30.8C155 85.5 203 64 256 64c106 0 192 86 192 192s-86 192-192 192c-40.8 0-78.6-12.7-109.7-34.4c-14.5-10.1-34.4-6.6-44.6 7.9s-6.6 34.4 7.9 44.6C151.2 495 201.7 512 256 512c141.4 0 256-114.6 256-256S397.4 0 256 0C185.3 0 121.3 28.7 75 75zm181 53c-13.3 0-24 10.7-24 24l0 104c0 6.4 2.5 12.5 7 17l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-65-65 0-94.1c0-13.3-10.7-24-24-24z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-lg font-bold" style={{ color: "#003366" }}>
                  {Math.round(metrics?.averageTimeSpent || 0 / 1000)}s
                </p>
                <h3 className="text-gray-500" style={{ color: "#ffa500" }}>
                  Avg Time Spent
                </h3>
              </div>
            </div>

            {/* Total Clicks */}
            <div className="dashboardsection-card flex gap-3 items-center bg-yellow-100 p-4 rounded-lg shadow-md">
              <div className="dashboardsection-image ">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34"
                  height="34"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="#fff"
                    d="M128 40c0-22.1 17.9-40 40-40s40 17.9 40 40l0 148.2c8.5-7.6 19.7-12.2 32-12.2c20.6 0 38.2 13 45 31.2c8.8-9.3 21.2-15.2 35-15.2c25.3 0 46 19.5 47.9 44.3c8.5-7.7 19.8-12.3 32.1-12.3c26.5 0 48 21.5 48 48l0 48 0 16 0 48c0 70.7-57.3 128-128 128l-16 0-64 0-.1 0-5.2 0c-5 0-9.9-.3-14.7-1c-55.3-5.6-106.2-34-140-79L8 336c-13.3-17.7-9.7-42.7 8-56s42.7-9.7 56 8l56 74.7L128 40zM240 304c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 96c0 8.8 7.2 16 16 16s16-7.2 16-16l0-96zm48-16c-8.8 0-16 7.2-16 16l0 96c0 8.8 7.2 16 16 16s16-7.2 16-16l0-96c0-8.8-7.2-16-16-16zm80 16c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 96c0 8.8 7.2 16 16 16s16-7.2 16-16l0-96z"
                  />
                </svg>
              </div>
              <div>
                <p className="text-lg font-bold" style={{ color: "#003366" }}>
                  {metrics.totalClicks}
                </p>
                <h3 className="text-gray-500" style={{ color: "#ffa500" }}>
                  Total Clicks
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-2/5">
          <div className="md:flex md:space-x-4  mb-4  mx-auto justify-center">
            {["daily", "weekly", "monthly", "yearly"].map((p) => (
              <button
                key={p}
                onClick={() => setPeriod(p)}
                className={`px-4 py-2 rounded-full font-medium m-2 ${
                  period === p
                    ? "bg-yellow-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
              >
                {p.charAt(0).toUpperCase() + p.slice(1)}
              </button>
            ))}
          </div>
          <ChartComponent
            jobViews={{
              labels: jobViewsData.totals.map((item) => item._id.date),
              data: jobViewsData.totals.map((item) => item.totalViews),
              period,
            }}
          />
        </div>
      </div>
      {/* Filters */}
      {/* Chart */}
      {/* Custom Data Table */}
      {jobViewsData.allViews?.length > 0 ? (
        <div className="mt-10 py-10">
          {" "}
          <CustomDataTable
            columns={columns}
            data={jobViewsData.allViews}
            totalRows={totalRows}
            rowsPerPageOptions={[10, 25, 50]}
            defaultRowsPerPage={perPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handlePerRowsChange}
            currentPage={page}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default JobViews;
