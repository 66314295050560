import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

const ApiSection = ({
  method,
  url,
  description,
  authToken,
  jobid,
  defaultQueryParams,
  requestBody,
  exampleResponses = {},
  statusDescriptions = {},
}) => {
  console.log(authToken, "authToken");
  const responseRef = useRef(null);
  const [response, setResponse] = useState("");
  const [responseHeaders, setResponseHeaders] = useState("");
  const [statusCode, setStatusCode] = useState(null);
  const [queryParams, setQueryParams] = useState(defaultQueryParams || []);
  const [isExpanded, setIsExpanded] = useState(true);
  const [routeId, setRouteId] = useState("");
  const [headers, setHeaders] = useState([
    { key: "Authorization", value: authToken || "" },
  ]);
  const [bodyData, setBodyData] = useState({
    token: "",
  });
  const domainpath = process.env.REACT_APP_API_DOMAIN_ENDPOINT;

  const handleTryItOut = async () => {
    let apiUrl;
    if (routeId != "") {
      apiUrl = `${domainpath}/api/v1/jobs/${routeId}`;
    } else {
      apiUrl = `${domainpath}/api/v1${url}`;
    }

    const queryString = queryParams
      .map((param) => `${param.key}=${encodeURIComponent(param.value)}`)
      .join("&");
    apiUrl += queryString ? `?${queryString}` : "";

    try {
      const mergedHeaders = headers.reduce((acc, header) => {
        if (header.key && header.value) acc[header.key] = header.value;
        return acc;
      }, {});
      var data = {
        token: bodyData.token,
      };
      const options = {
        method,
        url: apiUrl,
        headers: {
          "Content-Type": "application/json",
          ...mergedHeaders,
        },
        ...(method === "POST" && { data }),
      };

      const res = await axios(options);
      setResponse(JSON.stringify(res.data, null, 2));
      setResponseHeaders(res.headers);

      setStatusCode(res.status);
    } catch (error) {
      setResponse(
        JSON.stringify(
          {
            message: false,
            error: error.response
              ? error.response.data.message || error.response.data.error
              : error.message,
          },
          null,
          2
        )
      );
      setResponseHeaders(
        JSON.stringify(
          error.response ? error.response.headers : {},

          null,
          2
        )
      );
      setResponseHeaders(error.response ? error.response.headers : {});
      setStatusCode(error.response ? error.response.status : "Unknown");
    }
  };
  useEffect(() => {
    if (response && responseRef.current) {
      responseRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [response]);
  const handleHeaderChange = (index, key, value) => {
    const updatedHeaders = [...headers];
    updatedHeaders[index][key] = value;
    setHeaders(updatedHeaders);
  };

  const addHeader = () => {
    setHeaders([...headers, { key: "", value: "" }]);
  };

  const removeHeader = (index) => {
    const updatedHeaders = [...headers];
    updatedHeaders.splice(index, 1);
    setHeaders(updatedHeaders);
  };

  const handleParamChange = (index, key, value) => {
    const updatedParams = [...queryParams];
    updatedParams[index][key] = value;
    setQueryParams(updatedParams);
  };

  const addParam = () => {
    setQueryParams([...queryParams, { key: "", value: "" }]);
  };

  const removeParam = (index) => {
    const updatedParams = [...queryParams];
    updatedParams.splice(index, 1);
    setQueryParams(updatedParams);
  };

  const downloadResponse = () => {
    const blob = new Blob([response], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "response.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  const formatJson = (json) => {
    return json.replace(
      /"(.*?)":|"(.*?)"|([{}\[\]])|(\d+(\.\d+)?)/g,
      (match, key, string, brace, number) => {
        if (key)
          return `<span style="color: #ffa500; font-weight: bold;">"${key}":</span>`;
        if (string) return `<span style="color: #003366;">"${string}"</span>`;
        if (brace) return `<span style="color: #000000;">${brace}</span>`;
        if (number) return `<span style="color: #003366;">${number}</span>`;
        return match;
      }
    );
  };

  return (
    <div className="bg-gray-50 p-6 rounded-lg mb-6 shadow-lg">
      {/* Header Section */}
      <div
        className="flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h3 className="text-2xl font-bold">
          <span
            className={`inline-block px-3 py-1 rounded-md text-white text-lg ${
              method === "GET" ? "bg-green-500" : "bg-blue-500"
            }`}
          >
            {method}
          </span>{" "}
          <span style={{ color: "#003366" }} className="text-gray-800">
            {url}
          </span>
        </h3>
        <button
          className="text-blue-500 hover:underline font-medium text-lg"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? "Collapse" : "Expand"}
        </button>
      </div>

      {/* Expanded Content */}
      {isExpanded && (
        <div className="mt-4 text-left">
          {/* Description */}
          <h4 className="font-bold text-lg text-gray-800 mb-2">Description:</h4>
          <p className="text-gray-700 text-lg font-medium mb-4">
            {description}
          </p>
          {/* Global Headers Section */}
          {method !== "POST" ? (
            <div className="mb-6">
              <h4 className="font-bold text-lg text-gray-800 mb-2">Headers:</h4>
              <div className="flex mb-2 items-center">
                {/* Authorization Key (Read-Only) */}
                <input
                  type="text"
                  value="Authorization"
                  readOnly
                  className="w-1/3 p-2 bg-gray-200 text-gray-500 border border-gray-300 rounded-md mr-2"
                />
                {/* Authorization Value (Editable) */}
                <input
                  type="text"
                  placeholder="Enter token"
                  value={headers[0]?.value || ""}
                  onChange={(e) =>
                    setHeaders([
                      { key: "Authorization", value: e.target.value },
                    ])
                  }
                  className="w-1/3 p-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Query Parameters */}
          {method !== "POST" && jobid != "jobid" ? (
            <div className="mb-6">
              <h4 className="font-bold text-lg text-gray-800 mb-2">
                Query Parameters:
              </h4>
              {queryParams.map((param, index) => (
                <div key={index} className="flex mb-2 items-center">
                  <input
                    type="text"
                    placeholder="Key"
                    value={param.key}
                    onChange={(e) =>
                      handleParamChange(index, "key", e.target.value)
                    }
                    className="w-1/3 p-2 border border-gray-300 rounded-md mr-2"
                  />
                  <input
                    type="text"
                    placeholder="Value"
                    value={param.value}
                    onChange={(e) =>
                      handleParamChange(index, "value", e.target.value)
                    }
                    className="w-1/3 p-2 border border-gray-300 rounded-md mr-2"
                  />
                  <button
                    onClick={() => removeParam(index)}
                    className="bg-red-500 text-white px-3 py-2 rounded-md"
                  >
                    Remove
                  </button>
                </div>
              ))}
              <button
                onClick={addParam}
                className="bg-green-500 text-white px-4 py-2 rounded-md"
              >
                Add Parameter
              </button>
            </div>
          ) : (
            ""
          )}
          {jobid === "jobid" ? (
            <div className="mb-6">
              <h4 className="font-bold text-lg text-gray-800 mb-2">
                Route Parameter (id):
              </h4>
              <input
                type="text"
                placeholder="Enter Job ID"
                value={routeId}
                onChange={(e) => setRouteId(e.target.value)}
                className="w-1/2 p-2 border border-gray-300 rounded-md"
              />
            </div>
          ) : (
            ""
          )}
          {/* Request Section */}
          <h4 className="font-bold text-lg text-gray-800 mb-2">Request:</h4>
          <p className="text-gray-700 mb-2">
            <strong>Request URL:</strong> {`${domainpath}/api/v1${url}`}
          </p>
          {method === "POST" ? (
            <pre className="bg-gray-100 p-4 rounded-md text-sm text-gray-700 mb-4">
              curl -X {method} "{`${domainpath}/api/v1${url}`}" \ -H
              "Content-Type: application/json" \ -d '
              {JSON.stringify(bodyData, null, 2)}'
            </pre>
          ) : (
            <pre className="bg-gray-100 p-4 rounded-md text-sm text-gray-700 mb-4">
              curl -X {method} "
              {`${domainpath}/api/v1${url}${
                queryParams.length > 0
                  ? "?" +
                    queryParams
                      .map((param) => `${param.key}=${param.value}`)
                      .join("&")
                  : ""
              }`}
              " \ -H "Content-Type: application/json" \ -H "Authorization:{" "}
              {authToken}"
            </pre>
          )}
          {/* Execute API */}
          {method === "POST" && (
            <div className="mb-6">
              <h4 className="font-bold text-lg text-gray-800 mb-2">
                Request Body:
              </h4>

              <input
                type="text"
                placeholder="Enter Token"
                value={bodyData.token}
                onChange={(e) =>
                  setBodyData({ ...bodyData, token: e.target.value })
                }
                className="w-1/2 p-2 border border-gray-300 rounded-md"
              />
            </div>
          )}
          <button
            onClick={handleTryItOut}
            className="bg-blue-500 text-white px-6 py-3 rounded-md hover:bg-blue-600 shadow text-lg font-semibold"
          >
            Execute
          </button>
          {response && (
            <div className="mt-6" ref={responseRef}>
              <h4 className="font-bold text-lg text-gray-800 mb-2">
                Response:
              </h4>
              <div className="mb-4">
                <strong>Status Code:</strong> {statusCode}
              </div>
              <div className="mb-4">
                <strong>Response Headers:</strong>
                <pre className="bg-gray-100 p-4 rounded-md text-sm text-gray-700">
                  {JSON.stringify(responseHeaders, null, 2)}
                </pre>
              </div>
              <div className="mb-4">
                <strong>Response Body:</strong>
                <pre
                  className="bg-white text-black p-4 rounded-md text-base border border-gray-300 h-96 overflow-y-scroll custom-scrollbar"
                  style={{
                    fontSize: "16px",
                    lineHeight: "1.5",
                    fontFamily: "monospace",
                    textAlign: "left",
                    whiteSpace: "pre-wrap",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: formatJson(response),
                  }}
                ></pre>
              </div>
              <button
                onClick={downloadResponse}
                className="bg-green-500 text-white px-4 py-2 rounded-md"
              >
                Download Response
              </button>
            </div>
          )}
          {/* Status Codes */}
          <h4 className="font-bold text-lg mb-2">Status Codes:</h4>
          <ul className="pl-6 text-gray-700 mb-4">
            {Object.entries(statusDescriptions).map(([code, desc]) => (
              <li key={code}>
                <strong>{code}:</strong> {desc}
              </li>
            ))}
          </ul>
          {/* Example Responses */}
          <h4 className="font-bold text-lg mb-2">Example Responses:</h4>
          <div>
            <h5 className="font-bold text-sm">Success:</h5>
            <pre className="bg-gray-100 p-4 rounded-md text-sm text-gray-700 mb-2">
              {JSON.stringify(exampleResponses.success, null, 2)}
            </pre>
            <h5 className="font-bold text-sm">Error:</h5>
            <pre className="bg-gray-100 p-4 rounded-md text-sm text-gray-700">
              {JSON.stringify(exampleResponses.error, null, 2)}
            </pre>
          </div>
          {/* Response Section */}
        </div>
      )}
    </div>
  );
};

export default ApiSection;
