import React, { useState, useEffect } from "react";
import FeatherIcon from "feather-icons-react";
import Select2 from "../Common/Select2Wrapper";
const CustomDataTable = ({
  columns,
  data,
  totalRows,
  rowsPerPageOptions = [10, 25, 50],
  defaultRowsPerPage = 10,
  onPageChange,
  onRowsPerPageChange,
  currentPage,
  handleEdit,
  handleDelete,
  handleView,
  handleShare,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const [openDropdownId, setOpenDropdownId] = useState(null); // Track the open dropdown by ID

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id); // Toggle dropdown for the specific row
  };
  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage, onPageChange]);

  const handlePageClick = (page) => {
    onPageChange(page);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    onRowsPerPageChange(newRowsPerPage);
    onPageChange(1);
  };

  const startItem = (currentPage - 1) * rowsPerPage + 1;
  const endItem = Math.min(currentPage * rowsPerPage, totalRows);
  const renderPaginationButtons = () => {
    const paginationButtons = [];
    const maxVisibleButtons = 5; // Total buttons to display including current and nearby pages
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(currentPage + 2, totalPages);

    // Show "1" and ellipsis if current page is beyond the initial range
    if (startPage > 1) {
      paginationButtons.push(
        <button
          key={1}
          onClick={() => handlePageClick(1)}
          className={`mx-1 px-3 py-1 rounded-full shadow ${
            1 === currentPage
              ? "bg-yellow-400 text-white font-semibold"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          1
        </button>
      );

      if (startPage > 2) {
        paginationButtons.push(
          <span key="start-dots" className="mx-1 px-3 py-1 text-gray-700">
            ...
          </span>
        );
      }
    }

    // Display the current page and nearby pages
    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`mx-1 px-3 py-1 rounded-full shadow ${
            i === currentPage
              ? "bg-yellow-400 text-white font-semibold"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          {i}
        </button>
      );
    }

    // Show ellipsis and last page if not in the visible range
    if (endPage < totalPages - 1) {
      paginationButtons.push(
        <span key="end-dots" className="mx-1 px-3 py-1 text-gray-700">
          ...
        </span>
      );

      paginationButtons.push(
        <button
          key={totalPages}
          onClick={() => handlePageClick(totalPages)}
          className={`mx-1 px-3 py-1 rounded-full shadow ${
            totalPages === currentPage
              ? "bg-yellow-400 text-white font-semibold"
              : "bg-gray-200 text-gray-700"
          }`}
        >
          {totalPages}
        </button>
      );
    }

    return paginationButtons;
  };

  const [showMenu, setShowMenu] = useState(false);

  // const jobData = [
  //   {
  //     companylogo:
  //       "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
  //     jobTitle: "Senior Frontend Developer - Next.js",
  //     company: "Ajiva Infotech",
  //     location: "New York City, United States of America",
  //     views: 150,
  //     applicants: 25,
  //     shortlisted: 10,
  //     status: "Open",
  //     postedDate: "2024-08-30",
  //   },
  //   {
  //     companylogo:
  //       "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
  //     jobTitle: "Backend Developer - Node.js, Express, MongoDB",
  //     company: "Tech Solutions",
  //     location: "San Francisco Bay Area, United States of America",
  //     views: 200,
  //     applicants: 30,
  //     shortlisted: 12,
  //     status: "Open",
  //     postedDate: "2024-08-28",
  //   },
  //   {
  //     companylogo:
  //       "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
  //     jobTitle: "Senior UI/UX Designer - Web and Mobile Application Design",
  //     company: "Creative Agency",
  //     location: "Los Angeles Metropolitan Area, United States of America",
  //     views: 120,
  //     applicants: 20,
  //     shortlisted: 8,
  //     status: "Closed",
  //     postedDate: "2024-08-25",
  //   },
  //   {
  //     companylogo:
  //       "https://ajivainfotech.com/static/media/ajivalogo_45.4d887b2f187b6e34ec33.png",
  //     jobTitle: "Project Manager - Agile, Scrum, and Team Leadership",
  //     company: "Enterprise Corp",
  //     location: "Chicago Downtown,  United States of America",
  //     views: 180,
  //     applicants: 40,
  //     shortlisted: 15,
  //     status: "Open",
  //     postedDate: "2024-08-29",
  //   },
  // ];

  const statusOptions = [
    { value: "Publish", label: "Published", color: "#0085FF" },
    { value: "Hold", label: "Hold", color: "#003366" },
    { value: "Disabled", label: "Disabled", color: "#FFE513" },
    { value: "Closed", label: "Closed", color: "#FF0000" },
    { value: "Draft", label: "Draft", color: "#FFA500" },
    { value: "Block", label: "Block", color: "#000000" },
  ];
  const handlegetJobBystatus = (e) => {
    console.log("Selected Status:", e);
  };
  return (
    <div className="table-container view-details-table">
      <div>
        <div className="hrp-dasktop-secren-data-table">
          <table
            className="min-w-full bg-white border rounded shadow"
            id="tableContent"
          >
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="py-3 px-4 border-b text-left bg-gray-200 font-semibold text-gray-600"
                    style={{ width: col.width || "auto" }}
                  >
                    {col.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="hover:bg-gray-100 border-b transition duration-150"
                >
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="py-3 px-4 text-gray-700 ">
                      {col.selector ? (
                        col.selector(row)
                      ) : col.name === "Actions" ? (
                        <div className="relative">
                          {/* Trigger Button */}
                          <div
                            className=" cursor-pointer rounded-full p-2"
                            onClick={() => toggleDropdown(row._id)}
                          >
                            <FeatherIcon icon="more-vertical" />
                          </div>

                          {/* Dropdown Menu */}
                          {openDropdownId === row._id && (
                            <div className="absolute top-6 right-28 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg z-50">
                              {row.canView && (
                                <div
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => handleView(row._id)}
                                >
                                  <FeatherIcon
                                    icon="eye"
                                    className="mr-2 text-gray-500"
                                  />
                                  <span>View</span>
                                </div>
                              )}

                              {row.canEdit && (
                                <div
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => handleEdit(row._id)}
                                >
                                  <FeatherIcon
                                    icon="edit-2"
                                    className="mr-2 text-gray-500"
                                  />
                                  <span>Edit</span>
                                </div>
                              )}
                              {row.canShare && (
                                <div
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={(e) => handleShare(e, row)}
                                >
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M10.102 7.05V4C10.102 3.73478 10.0071 3.48043 9.83831 3.29289C9.6695 3.10536 9.44053 3 9.20179 3C8.96564 3.0011 8.73933 3.10526 8.57167 3.29L2.27042 10.29C2.18605 10.383 2.11908 10.4936 2.07338 10.6154C2.02768 10.7373 2.00415 10.868 2.00415 11C2.00415 11.132 2.02768 11.2627 2.07338 11.3846C2.11908 11.5064 2.18605 11.617 2.27042 11.71L8.57167 18.71C8.69804 18.8476 8.85811 18.9408 9.03189 18.9779C9.20566 19.015 9.38543 18.9944 9.54871 18.9186C9.712 18.8429 9.85157 18.7153 9.94997 18.5519C10.0484 18.3886 10.1012 18.1966 10.102 18V14.9H10.8671C12.2893 14.8764 13.6991 15.197 14.9986 15.8393C16.2982 16.4817 17.4563 17.4306 18.3926 18.62C18.5054 18.7869 18.6625 18.9104 18.8416 18.9731C19.0208 19.0359 19.2132 19.0348 19.3918 18.97C19.5736 18.9019 19.731 18.771 19.8411 18.5965C19.9512 18.422 20.0082 18.213 20.0039 18C20.0039 8.88 12.7305 7.32 10.102 7.05ZM10.8671 12.88C10.2651 12.8787 9.6636 12.9221 9.06676 13.01C8.85366 13.0459 8.65922 13.1656 8.51881 13.3473C8.3784 13.5289 8.30133 13.7606 8.30161 14V15.59L4.1788 11L8.30161 6.41V8C8.30161 8.26522 8.39645 8.51957 8.56527 8.70711C8.73408 8.89464 8.96305 9 9.20179 9C10.021 9 16.5022 9.2 17.9065 15.43C15.8682 13.7606 13.3995 12.8663 10.8671 12.88Z"
                                      fill="#8F8F8F"
                                    />
                                  </svg>
                                  <span>Share</span>
                                </div>
                              )}
                              {row.canDelete && (
                                <div
                                  className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => handleDelete(row._id)}
                                >
                                  <FeatherIcon
                                    icon="trash"
                                    className="mr-2 text-red-500"
                                  />
                                  <span>Delete</span>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      ) : (
                        row[col.selectorKey]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="pagination-container flex items-center justify-between mt-4">
        <div className="rows-per-page flex items-center">
          <span className="mr-2 text-gray-600">Rows per page:</span>
          <select
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            className="ml-2 border rounded p-1"
          >
            {rowsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <span className="ml-2 text-gray-600">
            {startItem}-{endItem} of {totalRows}
          </span>
        </div>

        <div className="pagination-controls flex items-center space-x-2">
          <button
            onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
            disabled={currentPage === 1}
            className={`pagination-button mx-1 px-3 py-1 rounded-full shadow ${
              currentPage === 1 ? "bg-gray-300" : "bg-white text-gray-700"
            }`}
          >
            Previous
          </button>

          <div className="pagination-buttons flex space-x-2">
            {renderPaginationButtons()}
          </div>

          <button
            onClick={() =>
              handlePageClick(Math.min(currentPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className={`pagination-button mx-1 px-3 py-1 rounded-full shadow ${
              currentPage === totalPages
                ? "bg-gray-300"
                : "bg-white text-gray-700"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomDataTable;
