import React, { useState, useEffect, useRef } from "react";
import api from "../../Api";
import CustomToastContainer from "../Common/CustomToastContainer";

const AddDomain = ({ onClose, domain, refreshData }) => {
  const [loading, setLoading] = useState(false);
  const toastRef = useRef();
  const [formData, setFormData] = useState({
    name: domain ? domain.name : "",
    url: domain ? domain.url : "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!formData.name || !formData.url) {
        toastRef.current.addToast("Both Name and URL are required", "error");
        setLoading(false);
        return;
      }
      if (domain) {
        await api.updateDomain(domain._id, formData);
        toastRef.current.addToast("Domain Updated Successfully", "success");
      } else {
        await api.createDomain(formData);
        toastRef.current.addToast("Domain Created Successfully", "success");
      }
      refreshData();
      onClose();
    } catch (error) {
      console.error("Error saving domain:", error);
      toastRef.current.addToast("Error saving domain", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center h-screen login_inform-inner m-auto p-20 job-page">
      <div className="form_upto hrp-employer-add-user m-3 loginDiv">
        <div className="bg-white shadow-md rounded p-8 mb-4">
          <h1 className="hrp-heading-size text-center hrp-About">
            {domain ? "Edit Domain" : "Add Domain"}
          </h1>
          <form
            onSubmit={handleSubmit}
            className="register-form-section animated-form"
          >
            <div className="mb-2">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="shadow w-full border rounded py-2 px-3"
                  required
                />
                <label htmlFor="name" className="form-input-label">
                  Domain Name
                </label>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="url"
                  value={formData.url}
                  onChange={handleInputChange}
                  className="shadow w-full border rounded py-2 px-3"
                  required
                />
                <label htmlFor="url" className="form-input-label">
                  Domain URL
                </label>
              </div>
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="hrp-find-course-btn py-2 px-6 rounded-full"
                disabled={loading}
              >
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                type="button"
                className="py-2 px-6 rounded-full bg-gray-300"
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
      <CustomToastContainer ref={toastRef} />
    </div>
  );
};

export default AddDomain;
